/*
 *
 * ProductDetailsPage constants
 *
 */

export const DESCRIPTION_BY_LANGUAGE = {
  en: {
    supplied: 'distributed by',
    origin: 'origin',
    price: 'price',
    VAT: 'VAT',
    preservation: 'preservation',
    status: 'status',
    unit: 'unit',
    category: 'category',
    description:
      'Providing ingredients, supplies for Restaurants, Cafes, Bars, Hotels, Supermarkets,... Rich and stable source of goods. Delivery to your place.',
  },
  vi: {
    supplied: 'được cung cấp bởi',
    origin: 'xuất xứ',
    price: 'giá',
    VAT: 'VAT',
    preservation: 'bảo quản ở',
    status: 'tình trạng',
    unit: 'đơn vị sản phẩm',
    category: 'sản phẩm thuộc danh mục',
    description:
      'Cung cấp nguyên liệu, vật dụng cho Nhà Hàng, Cà Phê, Bar, Khách Sạn, Siêu Thị,... Nguồn hàng phong phú, ổn định. Giao Hàng Tận Nơi.',
  },
};

export const CUSTOM_DESCRIPTION = {
  origin: {
    en: ['verified origin', 'from'],
    vi: ['nguồn gốc rõ ràng', 'từ'],
  },
  preservation: {
    en: ['frozen', 'refrigerated', 'room temperature'],
    vi: ['tủ đông', 'tủ lạnh', 'nhiệt độ phòng'],
  },
  status: {
    en: ['in stock', 'out of stock'],
    vi: ['còn hàng', 'hết hàng'],
  },
};

export const CONTENT_IMAGE_SIZE = {
  SMALL: 350,
  MEDIUM: 500,
  LARGE: 800,
};

export const MEDIA_QUERIES = {
  SMALL: '(min-width: 575px)',
  MEDIUM: '(min-width: 768px)',
  LARGE: '(min-width: 992px)',
};

export const CONTENT_IMAGE_RATIO = '16:10';

enum ActionTypes {
  DEFAULT_ACTION = 'app/ProductDetailsPage/DEFAULT_ACTION',
  FETCH_PRODUCT_REQUEST = 'app/ProductDetailsPage/FETCH_PRODUCT_REQUEST',
  FETCH_PRODUCT_SUCCESS = 'app/ProductDetailsPage/FETCH_PRODUCT_SUCCESS',
  FETCH_PRODUCT_FAILURE = 'app/ProductDetailsPage/FETCH_PRODUCT_FAILURE',
  FETCH_RELATE_PRODUCTS_REQUEST = 'app/ProductDetailsPage/FETCH_RELATE_PRODUCTS_REQUEST',
  FETCH_RELATE_PRODUCTS_SUCCESS = 'app/ProductDetailsPage/FETCH_RELATE_PRODUCTS_SUCCESS',
  FETCH_RELATE_PRODUCTS_FAILURE = 'app/ProductDetailsPage/FETCH_RELATE_PRODUCTS_FAILURE',
}

export default ActionTypes;
