/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'moment/locale/vi';
import 'moment/locale/en-gb';

import '!file-loader?name=[name].[ext]!./images/favicon.ico';

// Import all the third party stuff
import * as React from 'react';
import * as ReactDOM from 'react-dom';
// Needed for redux-saga es6 generator support
import * as Sentry from '@sentry/browser';

// Import root app
import App from 'containers/App/';
import { ConnectedRouter } from 'connected-react-router';
import { ErrorBoundary } from 'components/CategoryNav/ErrorBoundary';
// Import Language Provider
import LanguageProvider from 'containers/LanguageProvider';
import { Provider } from 'react-redux';
import configureStore from './configureStore';
import history from 'utils/history';
import { message, ConfigProvider } from 'antd';
import moment from 'moment';
import qs from 'qs';
import release from 'config/release.json';
import { translationMessages } from 'i18n';
import { pathnameChecker } from 'utils/url';
import { theme } from 'theme';
const search = qs.parse(window.location.search, { ignoreQueryPrefix: true });

if (search.lang) {
  const lang = (search.lang as string)?.slice(0, 2);
  window.localStorage.setItem('lang', lang);
} else if (pathnameChecker.isProductPage(window.location.pathname) && !search.lang) {
  window.localStorage.setItem('lang', 'vi');
}

message.config({ duration: 6 });

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    environment: process.env.APP_ENV || 'development',
    release: release.version || process.env.RELEASE || 'latest',
    dsn: 'https://40aa3cbb5ec949ef8a8a18a20d5c89ca@sentry.io/1408425',
  });
}

// Import i18n messages

moment.locale(window.localStorage.getItem('lang') === 'vi' ? 'vi' : 'en-gb');

// Create redux store with history
const initialState = {};
const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('root') as HTMLElement;

function perfNow() {
  if (window.performance) {
    return Math.round(performance.now());
  }

  return 10000;
}

function trackTiming(category, variable, value, label) {
  /** global ga */
  if (window.performance && typeof window.ga === 'function') {
    window.ga('send', {
      hitType: 'timing',
      timingCategory: category,
      timingVar: variable,
      timingLabel: label,
      timingValue: value,
    });
  }
}

trackTiming('JS', 'load', perfNow(), 'app.tsx');

const render = (messages, Component = App) => {
  trackTiming('JS', 'time_to_render', perfNow(), 'app.tsx');
  ReactDOM.render(
    <ErrorBoundary>
      <ConfigProvider theme={theme}>
        <Provider store={store}>
          <LanguageProvider messages={messages}>
            <ConnectedRouter history={history}>
              <Component />
            </ConnectedRouter>
          </LanguageProvider>
        </Provider>
      </ConfigProvider>
    </ErrorBoundary>,
    MOUNT_NODE,
    () => {
      trackTiming('JS', 'rendered', perfNow(), 'app.tsx');
    },
  );
};

declare const module: any;
if (module.hot) {
  module.hot.accept(['./i18n', './containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    // tslint:disable-next-line:max-line-length
    const App = require('./containers/App').default; // https://github.com/webpack/webpack-dev-server/issues/100
    render(translationMessages, App);
  });
}
// Chunked polyfill for browsers without Intl support
if (!(window as any).Intl) {
  new Promise((resolve) => {
    resolve(import('intl'));
  })
    .then(() => Promise.all([import('intl/locale-data/jsonp/en.js'), import('intl/locale-data/jsonp/vi.js')])) // eslint-disable-line prettier/prettier
    .then(() => render(translationMessages))
    .catch((err) => {
      throw err;
    });
} else {
  render(translationMessages);
}

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed

if (process.env.NODE_ENV === 'production') {
  const runtime = require('offline-plugin/runtime');
  runtime.install({
    autoUpdate: 1000 * 60 * 30,
    onUpdateReady: () => {
      runtime.applyUpdate();
    },
    onUpdated: () => {
      window.location.reload();
    },
    publicPath: '/',
  });
}

/* Already defined into separated script tag directly in index.hbs file */

// const tagManagerArgs = {
//   gtmId: 'GTM-WPGP67VB',
// };

// TagManager.initialize(tagManagerArgs);
