import gql from 'graphql-tag';

export default gql`
  mutation orderReorder($storeId: String!, $orderId: String!) {
    orderReorder(storeId: $storeId, orderId: $orderId) {
      success
      userErrors {
        field
        message
      }
      cart {
        redInvoiceInfo {
          registeredName
          registeredAddress
          redInvoiceNumber
        }
        deliveryNotes
        cartSet {
          paymentMethod
          deliveryNotes
          orderDeadline
          supplier {
            moq
            id
            name
            private
          }
          earliestDate
          deliveryDatetime {
            start
            end
          }
          deliveryTimeSlots {
            start
            end
          }
          total
          items {
            product {
              id
              name
              imageUrl
              note
              vat
              uom
              uomLocal
              price
              supplierInternalCode
              category {
                name
                parent {
                  name
                }
              }
            }
            quantity
          }
        }
      }
    }
  }
`;
