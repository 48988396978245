import { Menu } from 'antd';
import { Viewport } from 'components/ViewportProvider';
import { ContainerState } from 'containers/MainLayout/types';
import React, { Component } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Category } from 'types/schema';
import getSelectedCategory from 'utils/getSelectedCategory';
import media from 'utils/mediaStyle';
import SVGIcon from '../SVGIcon/SVGIcon';
import { ErrorBoundary } from './ErrorBoundary';
import Icons from './iconsNewDesign';
import rightArrow from './rightArrow.svg';
import { tags } from './sidebarFilterData';

const Icon = styled(SVGIcon)`
  width: 24pt;
  height: 24pt;
  margin: auto;
`;

const Label = styled.div`
  text-align: left;
  font-size: 14px;
  flex: 1;
  font-weight: 400;
  ${media.lg`
    margin-left: 8px;
  `};
`;

const SubLabel = styled(Link)`
  white-space: nowrap;
  border-radius: 4px;
  transition: all 0.3s ease;
  background-color: white;
  font-weight: 400;
  &:hover,
  &.selected {
    color: #2f904f !important;
  }
`;

const LabelWrapper = styled.div`
  min-width: 100%;
  box-sizing: border-box;
  white-space: nowrap;
  text-align: center;
  color: inherit;
  ${media.lg`
    display: flex;
  `};
`;

const CategoryNavContainer = styled.nav`
  border-collapse: collapse;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  .ant-menu-horizontal {
    border-bottom: none;
  }
  ${media.lg`
    flex-direction: column;
    background: transparent;
    width: auto;
    .ant-menu-vertical {
      .ant-menu-item.ant-menu-item {
        margin: 0;
      }
      border-right: none;
    }
  `};
  border-radius: 6px;

  .ant-menu {
    border-radius: 6px;
    border-top-right-radius: unset;
    border-top-left-radius: unset;
    border: 1px solid rgba(158, 158, 158, 0.2);
    border-top: none;
    font-weight: 400;
  }
  .ant-menu-submenu .ant-menu-sub {
    padding: 0 8px !important;
  }
  .ant-menu .ant-menu-submenu-title {
    height: auto;
    padding: 4pt;
    line-height: 26px;
    font-weight: 400;

    .ant-menu-submenu-arrow {
      display: none;
    }
  }
  .ant-menu li:last-child {
    .ant-menu-submenu-title {
      border-bottom: none;
    }
  }
  .ant-menu-horizontal > .ant-menu-item,
  .ant-menu-horizontal > .ant-menu-submenu {
    border-bottom: none;
  }
  .ant-menu-submenu:not(:first-child) {
    .ant-menu-submenu-title a {
      border-top: 1px solid rgba(158, 158, 158, 0.2);
    }
  }
`;
const CategoryNavShadow = styled.div`
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: -1;
  position: absolute;
  right: 0;
  top: 0;
  width: 4px;
  height: 100%;
  background-color: transparent;
  pointer-events: none;
  display: none;
  ${media.lg`
    display: block;
  `};
`;

const CategoryWrapper = styled.div`
  overflow: visible;
  z-index: 1;
  display: flex;
  ${media.lg`
    z-index: 20;
    top: 100pt;
  `};
  transition: left 0.3s ease-in-out;
  .ant-menu-submenu.ant-menu-submenu-popup {
    top: 0px !important;
    left: 186px !important;
    .ant-menu {
      box-shadow: unset;
      border: 1px solid rgba(158, 158, 158, 0.2);
      border-top: none;
      border-top-right-radius: unset;
      border-top-left-radius: unset;
      border-bottom-left-radius: unset;
      border-bottom-right-radius: 4px;
      width: 198px;
    }
  }
  .submenu-list {
    .ant-menu {
      height: 380.16px;
      padding: 16px 26px;
    }
  }
  .ant-menu-sub .ant-menu-item:hover {
    background: unset !important;
    color: #2f904f !important;
  }
`;

const SubMenu = styled(Menu.SubMenu)`
  &:first-of-type {
    a {
      overflow: hidden;
    }
  }
  &:last-of-type {
    a {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      overflow: hidden;
    }
  }
  a {
    background: white;
    transition: background-color 0.3s ease;
  }
  .ant-menu-submenu-title {
    padding: 0 !important;
    color: rgba(0, 0, 0, 0.85);
    a {
      padding: 2.5px 0px 2.5px 6px;
      color: inherit;
      display: block;
      &:focus {
        text-decoration: none;
      }
      div {
        align-items: center;
      }
    }
  }
  transition: all 0.3s ease;
  &.selected .ant-menu-submenu-title,
  &:hover .ant-menu-submenu-title {
    color: #2f904f;
  }
`;

export const CategoryContainer = styled.div`
  position: absolute;
  z-index: 100;
  top: 0;
  &:before {
    content: '';
    position: absolute;
    top: -20px;
    left: 0;
    width: 100%;
    height: 20px;
    cursor: pointer;
  }
  &:hover {
    display: block;
  }
  &.show-category {
    display: block;
  }
`;
const CategoryMobileContainer = styled.div`
  margin: 0 20px 21px;
  overflow: auto;
`;
const CategoryMobileWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 23px;
  column-gap: 24px;
  padding-bottom: 12px;
  @media (max-width: 400px) {
    column-gap: 18px;
  }
`;

const CategoryMobileItem = styled.div`
  a div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    & div {
      font-size: 16px;
      color: #424242;
      text-align: center;
      font-weight: 400;
    }
  }
  a div div div svg {
    width: 36px;
    height: 36px;
  }
  a > div {
    padding: 0 16px;
  }
`;
const Overlay = styled.div`
  display: ${({ overlay }) => (overlay ? 'block' : 'none')};
  content: '';
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 101px;
  left: 0px;
  background: rgba(66, 66, 66, 0.1);
  z-index: -1;
`;

interface CategoryNavState {
  sideNavOpened: boolean;
  categories: Category[];
}
interface CategoryNavProps extends RouteComponentProps {
  main?: ContainerState;
  overlay?: boolean;
  onCloseCategory?: () => void;
}

class CategoryNav extends Component<CategoryNavProps, CategoryNavState> {
  public state: CategoryNavState = {
    sideNavOpened: false,
    categories: [],
  };

  public getSelectedCategory = () => {
    const substate = this.props.main;
    const { location } = this.props;
    return getSelectedCategory(substate && substate.categories, location);
  };

  public static getDerivedStateFromProps(nextProps: CategoryNavProps) {
    if (nextProps.main) {
      const { sideNav, categories } = nextProps.main;
      const confectionIndex = categories.findIndex((item) => item.name === 'Confection');
      if (confectionIndex !== -1) categories.splice(confectionIndex, 1);
      return {
        sideNavOpened: sideNav,
        categories: categories,
      };
    }
    return {
      sideNavOpened: false,
      categories: [],
    };
  }

  public render() {
    const { sideNavOpened, categories } = this.state;
    const { location, overlay, onCloseCategory } = this.props;
    const selectedCategory = this.getSelectedCategory();
    const path = location.pathname === '/favorite' ? '/favorite' : '/market';
    return (
      <Viewport.Consumer>
        {({ width }) => {
          const isMobileSize = width < 1025;
          return (
            <ErrorBoundary>
              {!isMobileSize ? (
                <>
                  <CategoryContainer
                    isHomePage={location.pathname === '/'}
                    className={location.pathname === '/' && 'show-category'}
                  >
                    <CategoryWrapper sideNavOpen={sideNavOpened} id="products-category">
                      <CategoryNavContainer>
                        <Menu
                          getPopupContainer={() => document.getElementById('products-category') || document.body}
                          style={{ width: 198 }}
                        >
                          {tags.map((tag) => {
                            return (
                              <SubMenu
                                key={tag.name}
                                className={
                                  location.search.search(tag.value) !== -1 && path !== '/favorite' ? 'selected' : ''
                                }
                                title={
                                  <Link to={`/market?tags=${tag.value}`} onClick={onCloseCategory}>
                                    <LabelWrapper>
                                      {Icons[tag.name] && <Icon src={Icons[tag.name]} />}
                                      <Label>{tag.nameLocal}</Label>
                                    </LabelWrapper>
                                  </Link>
                                }
                              />
                            );
                          })}
                          {categories.map((category: Category, index) => {
                            return (
                              <SubMenu
                                key={category.name}
                                title={
                                  <Link to={`/market?category=${encodeURIComponent(category.name)}`}>
                                    <LabelWrapper onClick={onCloseCategory}>
                                      {Icons[category.name] && <Icon src={Icons[category.name]} />}
                                      <Label>{category.nameLocal}</Label>
                                      <Icon src={rightArrow} />
                                    </LabelWrapper>
                                  </Link>
                                }
                                className={
                                  selectedCategory &&
                                  selectedCategory.name.indexOf(category.name) > -1 &&
                                  path !== '/favorite'
                                    ? 'selected'
                                    : ''
                                }
                                popupClassName="submenu-list"
                              >
                                {category.children?.map((subCategory) => (
                                  <Menu.Item key={subCategory.name} style={{ padding: 0 }} onClick={onCloseCategory}>
                                    <SubLabel
                                      to={`/market?category=${encodeURIComponent(subCategory.name)}`}
                                      className={
                                        selectedCategory && selectedCategory.name === subCategory.name ? 'selected' : ''
                                      }
                                    >
                                      {subCategory.nameLocal}
                                    </SubLabel>
                                  </Menu.Item>
                                ))}
                              </SubMenu>
                            );
                          })}
                        </Menu>
                      </CategoryNavContainer>
                      <CategoryNavShadow />
                    </CategoryWrapper>
                  </CategoryContainer>
                  <Overlay overlay={overlay} onClick={onCloseCategory} />
                </>
              ) : (
                <CategoryMobileContainer>
                  <CategoryMobileWrapper>
                    {tags.map((tag) => {
                      return (
                        <CategoryMobileItem
                          key={tag.name}
                          className={location.search.search(tag.value) !== -1 && path !== '/favorite' ? 'selected' : ''}
                        >
                          <Link to={`${path}?tags=${tag.value}`}>
                            <LabelWrapper>
                              {Icons[tag.name] && <Icon src={Icons[tag.name]} />}
                              <Label>{tag.nameLocal}</Label>
                            </LabelWrapper>
                          </Link>
                        </CategoryMobileItem>
                      );
                    })}
                    {categories.map((category: Category) => {
                      return (
                        <CategoryMobileItem
                          key={category.name}
                          className={
                            selectedCategory &&
                            selectedCategory.name.indexOf(category.name) > -1 &&
                            path !== '/favorite'
                              ? 'selected'
                              : ''
                          }
                          popupClassName="submenu-list"
                        >
                          <Link to={`${path}?category=${encodeURIComponent(category.name)}`}>
                            <LabelWrapper>
                              {Icons[category.name] && <Icon src={Icons[category.name]} />}
                              <Label>{category.nameLocal}</Label>
                            </LabelWrapper>
                          </Link>
                        </CategoryMobileItem>
                      );
                    })}
                  </CategoryMobileWrapper>
                </CategoryMobileContainer>
              )}
            </ErrorBoundary>
          );
        }}
      </Viewport.Consumer>
    );
  }
}

export default withRouter(CategoryNav);
