import React, { useMemo } from 'react';
import SVGIcon from '../SVGIcon/SVGIcon';
import styled from 'styled-components';
import routes from './routes';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose, Dispatch } from 'redux';
import SeparatorIcon from './separator.svg';
import qs from 'qs';
import decodeQuery from '../../utils/decodeQuery';
import translations from 'translations';
import messages from './messages';
import selectProductDetailsPage from '../../containers/ProductDetailsPage/selectors';
import { ContainerState } from '../../containers/ProductDetailsPage/types';
import { setBreadCrumbDataMarkup } from '../../containers/MainLayout/actions';
import { createStructuredSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { IRouteBreadCrumb } from './type';
import { Skeleton } from 'antd';
import { pathnameChecker } from 'utils/url';

interface StateProps {
  productDetailsPage: ContainerState;
}

const homeRoute = {
  name: 'HomePage',
  nameLocal: translations(messages.home),
  path: '/',
};

const Icon = styled(SVGIcon)`
  width: 24pt;
  position: relative;
  top: -1px;
  flex-shrink: 0;
`;
const BreadCrumbWrapper = styled.ol`
  max-width: 1200px;
  height: 23px;
  margin: 0 auto;
  margin-top: 16px;
  padding: 0 10pt;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-top: 85pt;
  @media (max-width: 1024px) {
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0 12pt 8px;
  }
`;
const BreadCrumbItem = styled.li`
  display: flex;
  align-items: center;
  color: #9e9e9e;
  font-size: 14px;
  flex-shrink: 0;
  &:last-child {
    color: #424242;
    cursor: default;
    pointer-events: none;
  }
`;
const BreadCrumbNameLink = styled(Link)`
  color: #9e9e9e;
  font-size: 14px;
  flex-shrink: 0;
  @media (max-width: 992px) {
    &:hover {
      color: #9e9e9e;
    }
  }
`;
const BreadCrumbName = styled.p`
  color: #424242;
  font-size: 14px;
  flex-shrink: 0;
`;
const SkeletonWrapper = styled.div`
  width: 100%;
  .ant-skeleton-paragraph {
    display: none;
  }
  .ant-skeleton-title {
    margin-top: 0 !important;
    width: 50% !important;
  }
`;
const validSearchQueries = ['category', 'tags', 'supplier', 'q'];
const Breadcrumb = (props) => {
  const {
    main: { categories, favoriteCategories },
    router: { location },
    productDetailsPage,
    setBreadCrumbDataMarkup,
  } = props;

  const routesData = useMemo<IRouteBreadCrumb[]>(() => {
    const handleFlattenCategoriesTree = (categories, pathname: string, parentName: string = '') => {
      return categories.reduce((accumulate: IRouteBreadCrumb[], category) => {
        const route: IRouteBreadCrumb = {
          name: category.name,
          nameLocal: category.nameLocal,
          path: `/${pathname}?category=${encodeURIComponent(category.name)}`,
          parentName: parentName,
          type: pathname,
        };
        accumulate.push(route);
        if ('children' in category) {
          const childrenRoutes = handleFlattenCategoriesTree(category.children, pathname, route.name);
          accumulate.push(...childrenRoutes);
        }
        return accumulate;
      }, []);
    };
    const categoriesRoutes = handleFlattenCategoriesTree(categories, 'market');
    const favoriteCategoriesRoutes = handleFlattenCategoriesTree(favoriteCategories, 'favorite', 'Favorite');
    return [...routes, ...categoriesRoutes, ...favoriteCategoriesRoutes];
  }, [categories, favoriteCategories]);

  const breadCrumbData = useMemo<IRouteBreadCrumb[]>(() => {
    const { pathname, search } = location;
    const newRoutesData = JSON.parse(JSON.stringify(routesData));
    const querySearchValue = qs.parse(search, {
      ignoreQueryPrefix: true,
      decoder: decodeQuery,
    });
    const generateValidSearchQueriesString = () => {
      const validSearchQueriesObject = Object.keys(querySearchValue).reduce((accumulate, queryName) => {
        if (validSearchQueries.includes(queryName)) {
          return {
            ...accumulate,
            [queryName]: querySearchValue[queryName],
          };
        }
        return accumulate;
      }, {});
      return Object.keys(validSearchQueriesObject).length ? `?${qs.stringify(validSearchQueriesObject)}` : '';
    };
    let pathnameElements: string[] = `${pathname}${generateValidSearchQueriesString()}`.split('/').splice(1);
    const breadCrumbRoutes: IRouteBreadCrumb[] = [];
    // implement search results breadcrumb
    if ('q' in querySearchValue && pathnameElements[0].includes('market')) {
      breadCrumbRoutes.push({
        name: 'SearchResults',
        nameLocal: `${translations(messages.searchResults)} "${querySearchValue.q}"`,
        path: '',
      });
      setBreadCrumbDataMarkup([homeRoute, ...breadCrumbRoutes]);
      return [homeRoute, ...breadCrumbRoutes];
    }
    // implement product detail breadcrumb route
    if (pathnameElements[0] === 'products' && productDetailsPage?.product?.name) {
      const {
        product: { category },
      } = productDetailsPage;
      const subCategoryName = `${category.parent.name}/${category.name}`;
      const subCategoryValue = newRoutesData.find(
        (route) => route.name === subCategoryName && route.path.includes('market'),
      );
      pathnameElements = [subCategoryValue?.path?.slice(1)];
    }
    const getParentCategory = (currentPath: IRouteBreadCrumb) => {
      const targetCategory = newRoutesData.find(
        (route) => route.name === currentPath.parentName && route.path.includes(currentPath.type),
      );
      if (targetCategory) breadCrumbRoutes.unshift(targetCategory);
      if (targetCategory?.parentName) {
        getParentCategory(targetCategory);
      }
    };
    pathnameElements.forEach((path, index) => {
      const currentPath = newRoutesData.find((route) => route.path === `/${path}`);
      if (currentPath) {
        if (currentPath.name === 'UserInformation') {
          currentPath.path = '/user-management';
        }
        if (currentPath.name === 'GroupInformation') {
          currentPath.path = '/group-management';
        }
        breadCrumbRoutes.push(currentPath);
        if (currentPath.parentName) {
          getParentCategory(currentPath);
          if (productDetailsPage?.product?.name && pathnameChecker.isProductPage(pathname)) {
            breadCrumbRoutes.push({
              name: 'ProductDetail',
              nameLocal: productDetailsPage?.product?.name,
              path: '',
            });
          }
        }
      } else if (breadCrumbRoutes[index - 1] && 'childrenName' in breadCrumbRoutes[index - 1]) {
        const childrenRoute = newRoutesData.find((route) => route.name === breadCrumbRoutes[index - 1].childrenName);
        if (childrenRoute) breadCrumbRoutes.push(childrenRoute);
      }
    });
    if (breadCrumbRoutes.length) {
      setBreadCrumbDataMarkup([homeRoute, ...breadCrumbRoutes]);
    }
    return [homeRoute, ...breadCrumbRoutes];
  }, [location, categories, favoriteCategories, productDetailsPage]);
  return (
    <BreadCrumbWrapper>
      <>
        {productDetailsPage?.product?.loading === true ? (
          <SkeletonWrapper>
            <Skeleton active />
          </SkeletonWrapper>
        ) : (
          breadCrumbData.map((item, index) => (
            <BreadCrumbItem key={item.name}>
              {breadCrumbData.length - 1 !== index ? (
                <BreadCrumbNameLink to={item.path}>{item.nameLocal}</BreadCrumbNameLink>
              ) : (
                <BreadCrumbName>{item.nameLocal}</BreadCrumbName>
              )}
              {breadCrumbData.length - 1 !== index && <Icon src={SeparatorIcon} />}
            </BreadCrumbItem>
          ))
        )}
      </>
    </BreadCrumbWrapper>
  );
};
const mapStateToProps = createStructuredSelector<ApplicationRootState, StateProps>({
  productDetailsPage: selectProductDetailsPage(),
});
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setBreadCrumbDataMarkup: (payload: IRouteBreadCrumb[]) => dispatch(setBreadCrumbDataMarkup(payload)),
  };
};
export default compose(
  connect((state) => state),
  connect(mapStateToProps, mapDispatchToProps),
)(Breadcrumb);
