import React, { PureComponent } from 'react';
import { selectAddedProducts, selectAddingProducts, selectOrderDisabled } from 'containers/MainLayout/selectors';
import { updateMultipleProductQuantity, updateProductQuantity } from 'containers/MainLayout/actions';

import { InputProps, QuantityInput } from 'components/MarketItem/QuantityInput/QuantityInput';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { push } from 'connected-react-router';
import { Product } from 'types/schema';

interface StateProps {
  added: any;
  adding: any;
  label?: any;
}
interface OwnProps extends Omit<InputProps, 'productId' | 'goToSignIn' | 'loggedIn' | 'orderDisabled'> {
  product: Product;
  multiple?: boolean;
}
interface DispatchProps {
  onUpdateProductQuantity: (data: any) => void;
  onUpdateMultipleProductQuantity: (data: any) => void;
  goToSignIn: () => void;
}

type Props = StateProps & DispatchProps & OwnProps & { loggedIn: boolean; orderDisabled: boolean };

class ActiveQuantityInput extends PureComponent<Props> {
  public onChangeQuantity = (product: any) => (quantity: number) => {
    if (this.props.multiple) {
      this.props.onUpdateMultipleProductQuantity({
        quantity: quantity,
        product: product,
      });
    } else {
      if (this.props.added === quantity) {
        return;
      }
      this.props.onUpdateProductQuantity({
        quantity: quantity,
        product: product,
      });
    }
  };

  public render() {
    const { product, added, label, ...props } = this.props;
    return (
      <QuantityInput
        {...props}
        product={product}
        productId={product.id}
        category={product.category}
        value={added}
        onChange={this.onChangeQuantity(product)}
      />
    );
  }
}

const mapStateToProps = createStructuredSelector<any, any>({
  addedProducts: selectAddedProducts(),
  addingProducts: selectAddingProducts(),
  orderDisabled: selectOrderDisabled(),
});

const mapDispatchToProps = (dispatch): DispatchProps => {
  return {
    onUpdateProductQuantity: (data: any) => dispatch(updateProductQuantity(data)),
    onUpdateMultipleProductQuantity: (data: any) => dispatch(updateMultipleProductQuantity(data)),
    goToSignIn: () => dispatch(push('/signin')),
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { product, ...otherProps } = ownProps;
  const loggedIn = !!localStorage.getItem('token');
  const added = stateProps.addedProducts[product?.id];
  const adding = stateProps.addingProducts[product?.id];
  return {
    ...dispatchProps,
    ...otherProps,
    added: added || 0,
    adding: adding,
    product: product,
    loggedIn: loggedIn,
    ...stateProps.orderDisabled,
  };
};

const withConnect = connect<StateProps, DispatchProps, OwnProps, Props>(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
);

export default withConnect(ActiveQuantityInput);
