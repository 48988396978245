/*
 *
 * MainLayout constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/MainLayout/DEFAULT_ACTION',
  SIDE_NAV_OPEN = 'app/MainLayout/SIDE_NAV_OPEN',
  SIDE_NAV_CLOSE = 'app/MainLayout/SIDE_NAV_CLOSE',
  SIDE_NAV_TOGGLE = 'app/MainLayout/SIDE_NAV_TOGGLE',
  SIGNOUT = 'app/MainLayout/SIGNOUT',
  CHANGE_STORE_REQUEST = 'app/MainLayout/CHANGE_STORE_REQUEST',
  CHANGE_STORE_SUCCESS = 'app/MainLayout/CHANGE_STORE_SUCCESS',
  CHANGE_STORE_FAILURE = 'app/MainLayout/CHANGE_STORE_FAILURE',
  FETCH_CART_REQUEST = 'app/MainLayout/FETCH_CART_REQUEST',
  FETCH_CART_SUCCESS = 'app/MainLayout/FETCH_CART_SUCCESS',
  FETCH_CART_FAILURE = 'app/MainLayout/FETCH_CART_FAILURE',
  FETCH_USER_REQUEST = 'app/MainLayout/FETCH_USER_REQUEST',
  FETCH_USER_SUCCESS = 'app/MainLayout/FETCH_USER_SUCCESS',
  FETCH_USER_FAILURE = 'app/MainLayout/FETCH_USER_FAILURE',
  FETCH_BUYER_REQUEST = 'app/MainLayout/FETCH_BUYER_REQUEST',
  FETCH_BUYER_SUCCESS = 'app/MainLayout/FETCH_BUYER_SUCCESS',
  FETCH_BUYER_FAILURE = 'app/MainLayout/FETCH_BUYER_FAILURE',
  FETCH_CATEGORIES_REQUEST = 'app/MainLayout/FETCH_CATEGORIES_REQUEST',
  FETCH_CATEGORIES_SUCCESS = 'app/MainLayout/FETCH_CATEGORIES_SUCCESS',
  FETCH_CATEGORIES_FAILURE = 'app/MainLayout/FETCH_CATEGORIES_FAILURE',
  FETCH_FAVORITE_CATEGORIES_REQUEST = 'app/MainLayout/FETCH_FAVORITE_CATEGORIES_REQUEST',
  FETCH_FAVORITE_CATEGORIES_SUCCESS = 'app/MainLayout/FETCH_FAVORITE_CATEGORIES_SUCCESS',
  FETCH_FAVORITE_CATEGORIES_FAILURE = 'app/MainLayout/FETCH_FAVORITE_CATEGORIES_FAILURE',
  ADDING_TO_CART = 'app/MainLayout/ADDING_TO_CART',
  ADDED_TO_CART = 'app/MainLayout/ADDED_TO_CART',
  ADD_TO_CART_REQUEST = 'app/MainLayout/ADD_TO_CART_REQUEST',
  ADD_TO_CART_SUCCESS = 'app/MainLayout/ADD_TO_CART_SUCCESS',
  ADD_TO_CART_FAILURE = 'app/MainLayout/ADD_TO_CART_FAILURE',
  UPDATE_PRODUCT_QUANTITY = 'app/MainLayout/UPDATE_PRODUCT_QUANTITY',
  UPDATE_MULTIPLE_PRODUCT_QUANTITY = 'app/MainLayout/UPDATE_MULTIPLE_PRODUCT_QUANTITY',
  ADDED_MULTIPLE_TO_CART = 'app/MainLayout/ADDED_MULTIPLE_TO_CART',
  ADDING_MULTIPLE_TO_CART = 'app/MainLayout/ADDING_MULTIPLE_TO_CART',
  SUBMIT_MULTIPLE_PRODUCT_QUANTITY = 'app/MainLayout/SUBMIT_MULTIPLE_PRODUCT_QUANTITY',
  UPDATE_CART = 'app/MainLayout/UPDATE_CART',
  UPDATE_CART_REQUEST = 'app/MainLayout/UPDATE_CART_REQUEST',
  UPDATE_CART_SUCCESS = 'app/MainLayout/UPDATE_CART_SUCCESS',
  UPDATE_CART_FAILURE = 'app/MainLayout/UPDATE_CART_FAILURE',
  REMOVE_ITEM_TO_CART = 'app/MainLayout/REMOVE_ITEM_TO_CART',
  REMOVE_ITEM_TO_CART_REQUEST = 'app/MainLayout/REMOVE_ITEM_TO_CART_REQUEST',
  REMOVE_ITEM_TO_CART_SUCCESS = 'app/MainLayout/REMOVE_ITEM_TO_CART_SUCCESS',
  REMOVE_ITEM_TO_CART_FAILURE = 'app/MainLayout/REMOVE_ITEM_TO_CART_FAILURE',
  SET_PRODUCT_NOTE = 'app/MainLayout/SET_PRODUCT_NOTE',
  SET_PRODUCT_NOTE_REQUEST = 'app/MainLayout/SET_PRODUCT_NOTE_REQUEST',
  SET_PRODUCT_NOTE_SUCCESS = 'app/MainLayout/SET_PRODUCT_NOTE_SUCCESS',
  SET_PRODUCT_NOTE_FAILURE = 'app/MainLayout/SET_PRODUCT_NOTE_FAILURE',
  SET_LANG = 'app/MainLayout/SET_LANG',
  SET_SELECTED_STORE = 'app/MainLayout/SET_SELECTED_STORE',
  SET_FAVORITE = 'app/MainLayout/SET_FAVORITE',
  FAVORITE_REQUEST = 'app/MainLayout/FAVORITE_REQUEST',
  FAVORITE_SUCCESS = 'app/MainLayout/FAVORITE_SUCCESS',
  FAVORITE_FAILURE = 'app/MainLayout/FAVORITE_FAILURE',
  FETCH_SHORT_SUPPLIER_REQUEST = 'app/MainLayout/FETCH_SHORT_SUPPLIER_REQUEST',
  FETCH_SHORT_SUPPLIER_SUCCESS = 'app/MainLayout/FETCH_SHORT_SUPPLIER_SUCCESS',
  FETCH_SHORT_SUPPLIER_FAILURE = 'app/MainLayout/FETCH_SHORT_SUPPLIER_FAILURE',
  FETCH_ALL_SUPPLIER_REQUEST = 'app/MainLayout/FETCH_ALL_SUPPLIER_REQUEST',
  FETCH_ALL_SUPPLIER_SUCCESS = 'app/MainLayout/FETCH_ALL_SUPPLIER_SUCCESS',
  FETCH_ALL_SUPPLIER_FAILURE = 'app/MainLayout/FETCH_ALL_SUPPLIER_FAILURE',
  UPDATE_ENABLED_FEATURES = 'app/MainLayout/UPDATE_ENABLED_FEATURES',
  FETCH_RECENT_ORDERS_REQUEST = 'app/MainLayout/FETCH_RECENT_ORDERS_REQUEST',
  FETCH_RECENT_ORDERS_SUCCESS = 'app/MainLayout/FETCH_RECENT_ORDERS_SUCCESS',
  FETCH_RECENT_ORDERS_FAILURE = 'app/MainLayout/FETCH_RECENT_ORDERS_FAILURE',
  UPDATE_NOTE_TO_CART = 'app/MainLayout/UPDATE_NOTE_TO_CART',
  FETCH_ORDER_DISABLED_REQUEST = 'app/MainLayout/FETCH_ORDER_DISABLED_REQUEST',
  FETCH_ORDER_DISABLED_SUCCESS = 'app/MainLayout/FETCH_ORDER_DISABLED_SUCCESS',
  FETCH_ORDER_DISABLED_FAILURE = 'app/MainLayout/FETCH_ORDER_DISABLED_FAILURE',

  NOTIFICATION_REGISTER_TOKEN = 'app/MainLayout/NOTIFICATION_REGISTER_TOKEN',
  NOTIFICATION_REGISTER_TOKEN_REQUEST = 'app/MainLayout/NOTIFICATION_REGISTER_TOKEN_REQUEST',
  NOTIFICATION_REGISTER_TOKEN_SUCCESS = 'app/MainLayout/NOTIFICATION_REGISTER_TOKEN_SUCCESS',
  NOTIFICATION_REGISTER_TOKEN_FAILURE = 'app/MainLayout/NOTIFICATION_REGISTER_TOKEN_FAILURE',

  NOTIFICATION_UNREGISTER_TOKEN = 'app/MainLayout/NOTIFICATION_UNREGISTER_TOKEN',
  NOTIFICATION_UNREGISTER_TOKEN_REQUEST = 'app/MainLayout/NOTIFICATION_UNREGISTER_TOKEN_REQUEST',
  NOTIFICATION_UNREGISTER_TOKEN_SUCCESS = 'app/MainLayout/NOTIFICATION_UNREGISTER_TOKEN_SUCCESS',
  NOTIFICATION_UNREGISTER_TOKEN_FAILURE = 'app/MainLayout/NOTIFICATION_UNREGISTER_TOKEN_FAILURE',

  MARK_NOTIFICATION_AS_READ = 'app/MainLayout/MARK_NOTIFICATION_AS_READ',
  MARK_NOTIFICATION_AS_READ_REQUEST = 'app/MainLayout/MARK_NOTIFICATION_AS_READ_REQUEST',
  MARK_NOTIFICATION_AS_READ_SUCCESS = 'app/MainLayout/MARK_NOTIFICATION_AS_READ_SUCCESS',
  MARK_NOTIFICATION_AS_READ_FAILURE = 'app/MainLayout/MARK_NOTIFICATION_AS_READ_FAILURE',

  NOTIFICATIONS = 'app/MainLayout/NOTIFICATIONS',
  NOTIFICATIONS_REQUEST = 'app/MainLayout/NOTIFICATIONS_REQUEST',
  NOTIFICATIONS_SUCCESS = 'app/MainLayout/NOTIFICATIONS_SUCCESS',
  NOTIFICATIONS_FAILURE = 'app/MainLayout/NOTIFICATIONS_FAILURE',

  SET_HAS_UNREAD_NOTIFICATION = 'app/MainLayout/SET_HAS_UNREAD_NOTIFICATION',
  SET_BREAD_CRUMB_DATA_MARKUP = 'app/MainLayout/SET_BREAD_CRUMB_DATA_MARKUP',
  SET_SHOW_CATEGORY_BUTTON = 'app/MainLayout/SET_SHOW_CATEGORY_BUTTON',
  SET_PROMOTION_DATA_POPUP = 'app/MainLayout/SET_PROMOTION_DATA_POPUP',
  SET_SEARCH_PRODUCT_RESULTS_REQUEST = 'app/MainLayout/SET_SEARCH_PRODUCT_RESULTS_REQUEST',
  SET_SEARCH_PRODUCT_RESULTS_SUCCESS = 'app/MainLayout/SET_SEARCH_PRODUCT_RESULTS_SUCCESS',
  SET_SEARCH_PRODUCT_RESULTS_FAILURE = 'app/MainLayout/SET_SEARCH_PRODUCT_RESULTS_FAILURE',
  SET_SEARCH_PRODUCT_RESULTS_LOADING = 'app/MainLayout/SET_SEARCH_PRODUCT_RESULTS_LOADING',
  BUYER_WEB_VERSION = 'app/MainLayout/BUYER_WEB_VERSION',
  SET_BUYER_WEB_VERSION = 'app/MainLayout/SET_BUYER_WEB_VERSION',
}

export default ActionTypes;
