import { useCallback, useState } from 'react';

export type LocalStorageKey = 'notificationReminder' | 'blockHomePagePopup';

type UseLocalStorage<T> = [
  T, // type of data retrieve from local storage
  (value: T) => void, // set data to local storage
  () => void // remove data from local storage
];

/**
 * Storage a string or an object to local storage with given key
 * @param key of storage data
 * @param defaultValue could be string or and object
 * @returns [retrieved data,@function to set data,@function to remove data]
 */
const useLocalStorage = <T>(key: LocalStorageKey, defaultValue: T): UseLocalStorage<T> => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const value = window.localStorage.getItem(key);
      if (!value) {
        window.localStorage.setItem(key, JSON.stringify(defaultValue));
        return defaultValue;
      }
      return JSON.parse(value);
    } catch (error) {}
  });

  const setValue = useCallback(
    (newValue: T) => {
      setStoredValue(newValue);
      try {
        window.localStorage.setItem(key, JSON.stringify(newValue));
      } catch (error) {}
    },
    [key],
  );

  const removeValue = useCallback(
    () => {
      window.localStorage.removeItem(key);
    },
    [key],
  );

  return [storedValue, setValue, removeValue];
};

export default useLocalStorage;
