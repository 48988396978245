import en from './en';
import vi from './vi';
import * as Sentry from '@sentry/browser';

export default function(message) {
  if (!message) {
    return '';
  }
  try {
    const lang = window.localStorage.getItem('lang') || 'vi';
    const translatedMessage = lang === 'en' ? en[message.id] : vi[message.id];
    return translatedMessage || message.defaultMessage;
  } catch (error) {
    console.error(error);
    console.log(message);
    Sentry.withScope(scope => {
      scope.setExtra('message', message);
      Sentry.captureException(error);
    });
  }
}
