import { DownOutlined } from '@ant-design/icons';
import { Dropdown, MenuProps } from 'antd';
import React, { FC } from 'react';
import styled from 'styled-components';
import messages from '../Footer/messages';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';

const BlogItem = styled.a`
  display: block;
  padding: 8px;
  font-size: 14px;
  width: 200px;
`;
const DropdownText = styled.div`
  display: flex;
  gap: 5px;
  .anticon {
    font-size: 10px;
    margin-top: 2px;
  }
  cursor: pointer;
`;

const KamereoInfo: FC<RouteComponentProps> = (props) => {
  const { location } = props;
  const lang = window.localStorage.getItem('lang') || 'vi';
  const isHomePage = location.pathname === '/';
  const items: MenuProps['items'] = [
    {
      key: 1,
      label: (
        <BlogItem rel="noopener noreferrer" href={`https://kamereo.vn/`}>
          <FormattedMessage {...messages.home} />
        </BlogItem>
      ),
    },
    {
      key: 2,
      label: (
        <BlogItem
          rel={`noopener noreferrer ${!isHomePage ? 'nofollow' : undefined}`}
          href={`https://kamereo.vn/${lang === 'vi' ? 've-kamereo' : 'about-kamereo'}`}
        >
          <FormattedMessage {...messages.aboutUs} />
        </BlogItem>
      ),
    },
    {
      key: 3,
      label: (
        <BlogItem
          rel={`noopener noreferrer ${!isHomePage ? 'nofollow' : undefined}`}
          target="_blank"
          href={`https://kamereo.vn/${lang === 'vi' ? 'tuyen-dung' : 'career'}`}
        >
          <FormattedMessage {...messages.careers} />
        </BlogItem>
      ),
    },
    {
      key: 4,
      label: (
        <BlogItem href={`https://kamereo.vn/blog/${lang}/${lang === 'vi' ? 'tin-tuc-kamereo/' : 'kamereo-news/'}`}>
          <FormattedMessage {...messages.news} />
        </BlogItem>
      ),
    },
    {
      key: 5,
      label: (
        <BlogItem
          rel={`noopener noreferrer ${!isHomePage ? 'nofollow' : undefined}`}
          href={`https://kamereo.vn/blog/${lang}/${
            lang === 'vi' ? 'bao-chi-noi-gi-ve-kamereo/' : 'kamereo-in-the-newspaper/'
          }`}
        >
          <FormattedMessage {...messages.press} />
        </BlogItem>
      ),
    },
    {
      key: 6,
      label: (
        <BlogItem
          rel={`noopener noreferrer ${!isHomePage ? 'nofollow' : undefined}`}
          href={`https://kamereo.vn/blog/${lang}/${lang === 'vi' ? 'chung-nhan/' : 'certifications/'}`}
        >
          <FormattedMessage {...messages.certifications} />
        </BlogItem>
      ),
    },
  ];
  return (
    <Dropdown placement="bottomRight" menu={{ items: items }}>
      <DropdownText>
        KAMEREO
        <DownOutlined />
      </DropdownText>
    </Dropdown>
  );
};

export default withRouter(KamereoInfo);
