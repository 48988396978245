import { selectLoading, selectNoteItemLoading, selectSections } from 'containers/HomePage/selectors';
import { IHomeFavoritePayload, IHomeProductNotePayload, ISection } from 'containers/HomePage/types';
import React, { FC, useMemo, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import {
  Product,
  RecommendationItem,
  ShowcaseItem,
  TrackingAddToCartAddFromType,
  TrackingProductImpressionType,
} from 'types/schema';
import { Typography } from 'antd';
import styled from 'styles/styled-components';
import MarketItem from 'components/MarketItem/MarketItem';
import { selectAddedProducts, selectAddingProducts, selectUserPermissions } from 'containers/MainLayout/selectors';
import { AddedProduct, AddingProduct } from 'containers/MainLayout/types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import utilsMessages from 'utils/messages';
import ModalNote from 'components/Checkout/ModalNote';
import { setHomeFavirote, setHomeProductNote } from 'containers/HomePage/actions';
import media from 'utils/mediaStyle';
import { isRecommendationTag } from 'utils/utilities';
import { push } from 'connected-react-router';

interface ITrackingFrom {
  productImpression: TrackingProductImpressionType;
  addToCart: TrackingAddToCartAddFromType;
}

const { Title } = Typography;

const SectionTitle = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SectionHeading = styled(Title)`
  &.ant-typography {
    font-size: 20px;
    line-height: 1.4;
  }
`;

const WidgetWrapper = styled('div')`
  display: grid;
  padding: 16px 16px 0 16px;
  background-color: #fff;
  margin-bottom: 16px;
  border-radius: 4px;
  @media (max-width: 576px) {
    &:last-child {
      margin-bottom: 32px;
    }
  }
`;

const List = styled('div')`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 1px;
`;

const Item = styled('div')`
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  margin: 0 16px 24px 0;
  flex: 0 0 180px;
  max-width: 180px;
  ${media.md`
    box-shadow: none;
  `};
`;

interface IOwnProps {
  showcaseId: string;
  showcase: ShowcaseItem | RecommendationItem;
}

interface IStateProps {
  sections: ISection;
  loading: boolean;
  addedProducts: AddedProduct;
  addingProducts: AddingProduct;
  permissions: string[];
  noteItemLoading: boolean;
}

interface IDispatchProps {
  onToggleFavorite: (data: IHomeFavoritePayload) => void;
  onSetProductNote: (data: IHomeProductNotePayload) => void;
  gotoSignIn: () => void;
}

type Props = IStateProps & IDispatchProps & IOwnProps;

const HomeScreenWidget: FC<Props> = (props) => {
  const {
    noteItemLoading,
    showcase,
    showcaseId,
    sections,
    loading,
    addedProducts,
    addingProducts,
    permissions,
    onToggleFavorite,
    onSetProductNote,
    gotoSignIn,
  } = props;
  const section = sections[showcaseId];
  const loggedIn = !!localStorage.getItem('token');
  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [isOpenNoteModal, setIsOpenNoteModal] = useState<boolean>(false);

  const seeAllUrl = useMemo(() => {
    let url = '/market';
    if ('filter' in showcase) {
      if (showcase.filter.categoryName) {
        url += `?category=${encodeURIComponent(showcase.filter.categoryName)}`;
      }
      if (showcase.filter.tags) {
        url += `?tags=${showcase.filter.tags}`;
      }
    } else if (isRecommendationTag(showcaseId)) {
      url += `?tags=RECOMMENDED_FOR_YOU`;
    }
    return url;
  }, [showcase]);

  const trackingFrom = useMemo<ITrackingFrom>(() => {
    if ('filter' in showcase) {
      if (!!showcase.filter.tags) {
        return {
          productImpression: TrackingProductImpressionType.HomeTag,
          addToCart: TrackingAddToCartAddFromType.HomeTag,
        };
      }
      if (!!showcase.filter.categoryName) {
        return {
          productImpression: TrackingProductImpressionType.HomeCategory,
          addToCart: TrackingAddToCartAddFromType.HomeCategory,
        };
      }
    }
    // isRecommendation
    return {
      productImpression: TrackingProductImpressionType.HomeRecommendation,
      addToCart: TrackingAddToCartAddFromType.HomeRecommendation,
    };
  }, [showcase]);

  const onClickOpenNoteModal = useCallback(
    (product: Product, index: number) => {
      if (loggedIn) {
        setSelectedProduct(product);
        setSelectedIndex(index);
        setIsOpenNoteModal(true);
      } else {
        gotoSignIn();
      }
    },
    [setSelectedProduct, setSelectedIndex, setIsOpenNoteModal, loggedIn],
  );

  const onClickCloseNoteModal = useCallback(() => {
    setSelectedProduct(undefined);
    setSelectedIndex(-1);
    setIsOpenNoteModal(false);
  }, [setSelectedProduct, setSelectedIndex, setIsOpenNoteModal]);

  const onSubmitAddingNote = useCallback(
    (note: string) => {
      if (selectedProduct) {
        onSetProductNote({
          index: selectedIndex,
          section: showcaseId,
          note: note,
          id: selectedProduct.id,
        });
      }
      onClickCloseNoteModal();
    },
    [selectedProduct, showcaseId, selectedIndex, onClickCloseNoteModal],
  );

  if (!loading && !section.data.length) {
    return null;
  }

  return (
    <WidgetWrapper>
      <SectionTitle>
        <SectionHeading level={2}>{showcase.title}</SectionHeading>
        <Link to={seeAllUrl}>
          <FormattedMessage {...utilsMessages.seeAll} />
        </Link>
      </SectionTitle>
      <List>
        {section.data.map((product: Product, index: number) => (
          <Item key={product.id}>
            <MarketItem
              vertialOnly
              listName="Section Product List"
              loggedIn={loggedIn}
              gotoSignIn={gotoSignIn}
              product={product}
              loading={loading}
              favorite={product.isFavorite}
              note={product.note}
              added={addedProducts[product.id]}
              adding={addingProducts[product.id]}
              position={index}
              permissions={permissions}
              onToggleFavorite={() => onToggleFavorite({ product: product, index: index, section: showcaseId })}
              onOpenNote={() => onClickOpenNoteModal(product, index)}
              onDeleteNote={() => {
                onSetProductNote({ index: index, section: showcaseId, note: '', id: product.id });
                onClickCloseNoteModal();
              }}
              impressionProperties={{
                type: trackingFrom?.productImpression,
                tag: trackingFrom?.productImpression === TrackingProductImpressionType.HomeTag ? showcaseId : undefined,
              }}
              addToCartProperties={{
                addFrom: trackingFrom?.addToCart,
                tag: trackingFrom?.addToCart === TrackingAddToCartAddFromType.HomeTag ? showcaseId : undefined,
              }}
            />
          </Item>
        ))}
      </List>
      <ModalNote
        headerLabel={(selectedProduct && selectedProduct.name) || ''}
        isOpen={isOpenNoteModal}
        confirmLoading={noteItemLoading}
        onSubmit={onSubmitAddingNote}
        onClose={onClickCloseNoteModal}
        note={(selectedProduct && selectedProduct.note) || ''}
      />
    </WidgetWrapper>
  );
};

const mapStateToProps = createStructuredSelector<ApplicationRootState, IStateProps>({
  sections: selectSections(),
  loading: selectLoading(),
  addedProducts: selectAddedProducts(),
  addingProducts: selectAddingProducts(),
  permissions: selectUserPermissions(),
  noteItemLoading: selectNoteItemLoading(),
});

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
  onToggleFavorite: (data: IHomeFavoritePayload) => dispatch(setHomeFavirote(data)),
  onSetProductNote: (data: IHomeProductNotePayload) => dispatch(setHomeProductNote(data)),
  gotoSignIn: () => dispatch(push('/signin')),
});

const withConnect = connect<IStateProps, IDispatchProps, IOwnProps>(mapStateToProps, mapDispatchToProps);

export default withConnect(HomeScreenWidget);
