import gql from 'graphql-tag';

export default gql`
  mutation orderCreate($draftToken: String!, $storeId: String!) {
    orderCreate(draftToken: $draftToken, storeId: $storeId) {
      orders {
        id
        items {
          product {
            id
          }
        }
      }
      success
      userErrors {
        field
        message
      }
      cart {
        redInvoiceInfo {
          registeredName
          registeredAddress
          redInvoiceNumber
        }
        deliveryNotes
        cartSet {
          paymentMethod
          deliveryNotes
          orderDeadline
          supplier {
            moq
            id
            name
            private
          }
          earliestDate
          deliveryDatetime {
            start
            end
          }
          deliveryTimeSlots {
            start
            end
          }
          total
          items {
            product {
              id
              name
              note
              vat
              uom
              uomLocal
              price
            }
            quantity
            price
            orderedPrice
          }
        }
      }
    }
  }
`;
