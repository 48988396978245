/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import history from 'utils/history';
import languageProviderReducer from 'containers/LanguageProvider/reducer';

import { splitReducer } from '@splitsoftware/splitio-redux';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  const appReducer = combineReducers({
    language: languageProviderReducer,
    ...(process.env.SPLITIO_ON === 'true' ? { splitio: splitReducer } : {}),
    ...injectedReducers,
  });
  const rootReducer = (state: any, action: any) => {
    let newState = state;
    if (action.type === 'app/MainLayout/SIGNOUT') {
      newState = undefined;
    }
    return appReducer(newState, action);
  };
  // Wrap the root reducer and return a new root reducer with router state
  const mergeWithRouterState = connectRouter(history);
  return mergeWithRouterState(rootReducer);
}
