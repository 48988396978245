/**
 *
 * @param str a random string could includes special characters
 * @returns a slug string removed special characters
 */
export function convertStringToSlug(str: string): string {
  if (!str) {
    return '';
  }

  let convertSlugStr = str;
  convertSlugStr = convertSlugStr.toLowerCase();
  convertSlugStr = convertSlugStr.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  convertSlugStr = convertSlugStr.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  convertSlugStr = convertSlugStr.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  convertSlugStr = convertSlugStr.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  convertSlugStr = convertSlugStr.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  convertSlugStr = convertSlugStr.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  convertSlugStr = convertSlugStr.replace(/đ/g, 'd');
  // Some system encode vietnamese combining accent as individual utf-8 characters
  convertSlugStr = convertSlugStr.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  convertSlugStr = convertSlugStr.replace(/\u02C6|\u0306|\u031B/g, ''); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư

  convertSlugStr = convertSlugStr.trim();
  // Remove punctuations
  convertSlugStr = convertSlugStr.replace(
    /!|@|%|\^|\*|\(|\)|\+|=|<|>|\?|\/|,|\.|:|;|'|"|&|#|\[|\]|~|\$|_|`|{|}|\||\\/g,
    '',
  );
  // Replace spaces with hyphens
  convertSlugStr = convertSlugStr.replace(/ +/g, '-');
  const sanitizedSlug = convertSlugStr.replace(/[^a-zA-Z0-9-]/g, '');
  return sanitizedSlug;
}

/**
 *
 * @param str a random string could includes special characters
 * @param supplierInternalCode a series of string to parse into supplierInternalCode
 * @returns a standard string combined with str and supplierInternalCode
 */
export default function generateProductSlug(
  str?: string | null,
  supplierInternalCode?: string | null,
  lang?: string | null,
): string {
  if (!str || !supplierInternalCode) {
    return '';
  }
  const strToSlug = convertStringToSlug(str);
  if (!strToSlug) return '';

  return `${convertStringToSlug(str)}-${supplierInternalCode}${lang && lang !== 'vi' ? `?lang=${lang}` : ''}`;
}
