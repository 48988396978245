import { RouteProps } from 'react-router-dom';
import { Category } from 'types/schema';
import { parse } from './queryString';

/**
 * Return a Category base on the category search query
 * @param categories is an array of Category type, location is a location from RouteProps in react router
 * @returns A category based on search property in location object
 */

const getSelectedCategory = (
  categories: Category[] | undefined,
  location: RouteProps['location'],
): Category | undefined => {
  const search = parse(location && location.search);
  if (!categories || categories.length === 0 || !search.filter || !search.filter.categoryName) {
    return undefined;
  }

  const name = search.filter.categoryName;
  const parent = categories.find((c: Category) => name.indexOf(c.name) > -1);
  if (parent && name.indexOf('/') > -1) {
    const child = parent.children.find((c: Category) => c.name === name);
    if (child) {
      return {
        ...child,
        parent: parent,
      };
    }
    return child;
  }
  return parent;
};
export default getSelectedCategory;
