import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Carousel, Row } from 'antd';
import BannerWidget from 'components/BannerWidget';
import { Viewport } from 'components/ViewportProvider';
import React, { FC } from 'react';
import styled from 'styles/styled-components';
import { BannerItemUnion, ShowPopupBannerItem } from 'types/schema';
import media from 'utils/mediaStyle';
import CategoryNav from '../../containers/CategoryNav';

const BannerSliderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;
  @media (max-width: 767px) {
    width: 95%;
    left: 50%;
    transform: translateX(-50%);
  }

  .ant-carousel,
  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-slide > div {
    height: 100%;
  }

  .ant-carousel {
    .slick-slider {
      .slick-next,
      .slick-prev {
        background: #fff;
        z-index: 3;
        color: #595959;
        padding: 26px 13px;
        filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.25));
        font-size: 14px;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateY(-50%);
        opacity: 1;
        transition: opacity 0.2s ease;
        &:after {
          display: none;
        }
        &:before {
          content: '';
        }
      }

      .slick-prev {
        border-radius: 0px 4px 4px 0px;
      }

      .slick-next {
        border-radius: 4px 0px 0px 4px;
      }
      @media (max-width: 576px) {
        .slick-prev,
        .slick-next {
          display: none;
        }
      }
    }
  }

  .slick-slider {
    // background: red;

    .slick-dots-bottom {
      bottom: 22px;
    }

    .slick-prev {
      left: 0;
    }

    .slick-next {
      right: 0;
    }

    .slick-dots {
      li {
        margin: 0 4px;
      }
      button {
        border-radius: 50%;
        width: 8px;
        height: 8px;
      }
      li.slick-active button {
        width: 8px;
      }
    }
  }
`;

interface ArrowButtonProps {
  type: 'left' | 'right';
  className?: string;
  onClick?: () => void;
}

const Arrow = ({ type, className, onClick }: ArrowButtonProps) => (
  <button className={className} onClick={onClick}>
    <LegacyIcon type={type} />
  </button>
);

interface IProps {
  banners: BannerItemUnion[];
  onOpenPopup: (modalData: ShowPopupBannerItem) => void;
}

const BannerSliderRowWrapper = styled.div`
  ${media.lg`
  height: 382px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  `}
`;

const BannerSliderRow = styled(Row)`
  margin: 20pt 0 22pt 0;
  position: relative;
  @media (max-width: 767px) {
    margin: 0pt 0 22pt 0;
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100px;
      left: 0;
      top: -65px;
      background: rgb(76, 175, 80);
      border-bottom-left-radius: 24px;
      border-bottom-right-radius: 24px;
    }
  }
  &.ant-row {
    padding-bottom: 32%;
    height: 0;
  }
  ${media.lg`
    &.ant-row {
      margin-left: 160pt;
      padding-bottom: 27.5%;
      width: 100%;
    }
  `};
`;

const BannerSlider: FC<IProps> = (props) => {
  const { banners, onOpenPopup } = props;
  return (
    <Viewport.Consumer>
      {({ width }) => (
        <BannerSliderRowWrapper>
          {width > 1024 && <CategoryNav />}
          <BannerSliderRow>
            <BannerSliderWrapper>
              <Carousel
                autoplay
                autoplaySpeed={5000}
                dots
                arrows
                nextArrow={<Arrow type="right" />}
                prevArrow={<Arrow type="left" />}
              >
                {banners.map((banner, index) => (
                  <BannerWidget key={index.toString()} banner={banner} onOpenPopup={onOpenPopup} />
                ))}
              </Carousel>
            </BannerSliderWrapper>
          </BannerSliderRow>
        </BannerSliderRowWrapper>
      )}
    </Viewport.Consumer>
  );
};

export default BannerSlider;
