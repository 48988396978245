import React from 'react';
import { debounce } from 'lodash';

export const Viewport = React.createContext({ width: 992 });

interface ViewportProviderState {
  width: number;
}

export default class ViewportProvider extends React.Component<any, ViewportProviderState> {
  constructor(props) {
    super(props);
    this.state = {
      width: window.document.body.clientWidth,
    };
  }

  public updateDimensions = debounce(() => {
    this.setState({
      width: window.document.body.clientWidth,
    });
  }, 300);

  public componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  public render() {
    const width = this.state.width;
    return <Viewport.Provider value={{ width: width }}>{this.props.children}</Viewport.Provider>;
  }
}
