const getParamObjectByLocationSearch = (search: string) => {
  const paramsList = search.substring(1).split('&');
  const params: { [k: string]: string } = {};
  paramsList.map((p) => {
    const keyValue = p.split('=');
    params[keyValue[0]] = keyValue[1];
  });
  return params;
};

const pathnameChecker = {
  isProductPage: (currentPathname: string) => currentPathname.includes('/products'),
  isHomePage: (currentPathname: string) => currentPathname === '/',
  isOtherPage: (currentPathname: string, checkingPathname: string) => currentPathname === checkingPathname,
};

export { getParamObjectByLocationSearch, pathnameChecker };
