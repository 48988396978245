import React, { memo, useCallback, useMemo, useState } from 'react';
import { Modal, Spin } from 'antd';
import CarouselBanner from '../CarouselBanner';
import match from 'utils/match';
import styled from 'styles/styled-components';
import Button from 'components/Button/Button';
import {
  CarouselBannerWidget,
  RecommendationWidget,
  ShowcaseWidget as ShowcaseWidgetType,
  ShowPopupBannerItem,
  ShowWebViewBannerItem,
  WidgetUnion,
} from 'types/schema';
import HomeScreenWidget from 'components/HomeScreenWidget';
import CategoryNav from '../../containers/CategoryNav';
import { Viewport } from 'components/ViewportProvider';

interface IProps {
  widgets: WidgetUnion[];
}

const PopUpImageContainer = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 42%;
  position: relative;
  img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`;

const PopupContainer = styled(Modal)`
  min-width: 320px;

  .ant-modal-body {
    padding: 0px;
  }
`;

const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
`;

const IframeContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
`;

const ContentContainer = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const HTMLContent = styled.div`
  font-size: 16px;
  white-space: pre-wrap;
  display: flex;
  flex-direction: column;
  img {
    width: 100%;
  }
  ul,
  ol {
    padding-left: 16px;
  }

  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
  }

  li {
    display: flex;

    ::before {
      content: '\\2022';
      color: rgba(0, 0, 0, 0.87);
      font-weight: bold;
      display: inline-block;
      margin-right: 0.5em;
      margin-left: -1em;
    }
  }
`;

const HeadingContainer = styled.div`
  font-size: 24px;
  padding: 24px;
`;

const LoadingWrapper = styled.div`
  position: absolute;
  top: calc(50% - 75px);
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Widgets = memo((props: IProps) => {
  const { widgets } = props;

  const [isShowModal, setIsShowModal] = useState(false);
  const [modalWidgetData, setModalData] = useState<ShowPopupBannerItem | ShowWebViewBannerItem>();

  const onClose = useCallback(() => {
    setModalData(undefined);
    setIsShowModal(false);
  }, []);

  const onOpenModal = useCallback((modalData) => {
    setModalData(modalData);
    setIsShowModal(true);
  }, []);
  const renderWidgetItem = useCallback(
    (widget) => {
      return match(widget, {
        CarouselBannerWidget: (carouselBannerwidget: CarouselBannerWidget) => (
          <CarouselBanner
            key={carouselBannerwidget.widgetId}
            banners={carouselBannerwidget.banner.items}
            onOpenPopup={onOpenModal}
          />
        ),
        RecommendationWidget: (recommendationWidget: RecommendationWidget) => {
          return (
            <HomeScreenWidget
              key={recommendationWidget.widgetId}
              showcaseId={recommendationWidget.widgetId}
              showcase={recommendationWidget.item}
            />
          );
        },
        ShowcaseWidget: (showcaseWidgetType: ShowcaseWidgetType) => {
          return (
            <HomeScreenWidget
              key={showcaseWidgetType.widgetId}
              showcaseId={showcaseWidgetType.widgetId}
              showcase={showcaseWidgetType.item}
            />
          );
        },
        CategoryMobile: (categoryMobile: { type: string; key: string }) => {
          return (
            <Viewport.Consumer>
              {({ width }) => width < 1025 && <CategoryNav key={categoryMobile.key} />}
            </Viewport.Consumer>
          );
        },
      });
    },
    [onOpenModal],
  );

  const content = useMemo(() => {
    const categoryMobile = {
      type: 'CategoryMobile',
      key: 'CATEGORY_MOBILE',
    };
    if (!widgets.length) {
      return [];
    }
    const newWidgets = JSON.parse(JSON.stringify(widgets));
    newWidgets.splice(1, 0, categoryMobile);
    return newWidgets.map(renderWidgetItem);
  }, [widgets, renderWidgetItem]);

  return (
    <div>
      {content.length ? (
        content
      ) : (
        <LoadingWrapper>
          <Spin spinning={true} />
        </LoadingWrapper>
      )}
      {modalWidgetData &&
        match(modalWidgetData, {
          ShowPopupBannerItem: (popupItem: ShowPopupBannerItem) => (
            <PopupContainer open={isShowModal} onCancel={onClose} footer={null}>
              {!!popupItem.popupImageUrl && (
                <PopUpImageContainer>
                  <img src={popupItem.popupImageUrl} />
                </PopUpImageContainer>
              )}
              <ContentContainer>
                <HTMLContent dangerouslySetInnerHTML={{ __html: popupItem.popupContent }} />
                <Button type="secondary" block onClick={onClose}>
                  {popupItem.buttonText}
                </Button>
              </ContentContainer>
            </PopupContainer>
          ),
          ShowWebViewBannerItem: (iframeItem: ShowWebViewBannerItem) => (
            <PopupContainer open={isShowModal} onCancel={onClose} footer={null} width={'88.5%'}>
              <HeadingContainer>
                <h3>{iframeItem.title}</h3>
              </HeadingContainer>
              <IframeContainer>
                <Iframe
                  src={iframeItem.url}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                />
              </IframeContainer>
            </PopupContainer>
          ),
        })}
    </div>
  );
});

export default Widgets;
