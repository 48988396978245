import translations from 'translations';
import messages from './messages';
import utilMessages from 'utils/messages';
import { IRouteBreadCrumb } from './type';
const routes: IRouteBreadCrumb[] = [
  {
    name: 'Market',
    nameLocal: translations(messages.market),
    path: '/market',
  },
  {
    name: 'BestDeals',
    nameLocal: translations(utilMessages.BEST_DEALS),
    path: '/market?tags=BEST_DEALS',
  },
  {
    name: 'NewProducts',
    nameLocal: translations(messages.newProducts),
    path: '/market?tags=NEW_PRODUCTS',
  },
  {
    name: 'BestPrices',
    nameLocal: translations(utilMessages.BEST_PRICES),
    path: '/market?tags=BEST_PRICES',
  },
  {
    name: 'Recommended',
    nameLocal: translations(messages.recommended),
    path: '/market?tags=RECOMMENDED_FOR_YOU',
  },
  {
    name: 'Combo',
    nameLocal: translations(utilMessages.SUPER_COMBO),
    path: '/market?tags=SUPER_COMBO',
  },
  {
    name: 'CpProducts',
    nameLocal: translations(utilMessages.CP_PRODUCTS),
    path: '/market?tags=CP_PRODUCTS',
  },
  {
    name: 'JpProducts',
    nameLocal: translations(utilMessages.JAPANESE_PRODUCTS),
    path: '/market?tags=JP_PRODUCTS',
  },
  {
    name: 'JapaneseProducts',
    nameLocal: translations(utilMessages.JAPANESE_PRODUCTS),
    path: '/market?tags=JAPANESE_PRODUCTS',
  },
  {
    name: 'BookInAdvance',
    nameLocal: translations(utilMessages.BOOK_IN_ADVANCE),
    path: '/market?tags=BOOK_IN_ADVANCE',
  },
  {
    name: 'VinaMilkAndDalat',
    nameLocal: translations(utilMessages.VINAMILK_DALAT_MILK),
    path: '/market?tags=VINAMILK_DALAT_MILK',
  },
  {
    name: 'Torani',
    nameLocal: translations(utilMessages.TORANI),
    path: '/market?tags=TORANI',
  },
  {
    name: 'VegetableSuperSaving',
    nameLocal: translations(utilMessages.VEGETABLE_SUPER_SAVING),
    path: '/market?tags=VEGETABLE_SUPER_SAVING',
  },
  {
    name: 'Fruits',
    nameLocal: translations(utilMessages.FRUITS),
    path: '/market?tags=FRUITS',
  },
  {
    name: 'DishWashingLiquid',
    nameLocal: translations(utilMessages.DISHWASHING_LIQUID),
    path: '/market?tags=DISHWASHING_LIQUID',
  },
  {
    name: 'Lettuce',
    nameLocal: translations(utilMessages.LETTUCE),
    path: '/market?tags=LETTUCE',
  },
  {
    name: 'MeatZero',
    nameLocal: translations(utilMessages.MEAT_ZERO),
    path: '/market?tags=MEAT_ZERO',
  },
  {
    name: 'DalatCarrot',
    nameLocal: translations(messages.dalatCarrot),
    path: '/market?tags=DALAT_CARROT',
  },
  {
    name: 'FlashSales',
    nameLocal: translations(utilMessages.FLASH_SALES),
    path: '/market?tags=FLASH_SALES',
  },
  {
    name: 'ChineseProducts',
    nameLocal: translations(utilMessages.RAU_CU_QUA_TRUNG_QUOC),
    path: '/market?tags=RAU_CU_QUA_TRUNG_QUOC',
  },
  {
    name: 'LocalFruits',
    nameLocal: translations(utilMessages.TRAI_CAY_NOI_DIA),
    path: '/market?tags=TRAI_CAY_NOI_DIA',
  },
  {
    name: 'ImportedFruits',
    nameLocal: translations(utilMessages.TRAI_CAY_NHAP_KHAU),
    path: '/market?tags=TRAI_CAY_NHAP_KHAU',
  },
  {
    name: 'KamereoLabel',
    nameLocal: translations(utilMessages.THUONG_HIEU_KAMEREO),
    path: '/market?tags=THUONG_HIEU_KAMEREO',
  },
  {
    name: 'FreshMushroom',
    nameLocal: translations(utilMessages.NAM_TUOI_NGON),
    path: '/market?tags=NAM_TUOI_NGON',
  },
  {
    name: 'EcoFriendly',
    nameLocal: translations(utilMessages.THAN_THIEN_MOI_TRUONG),
    path: '/market?tags=THAN_THIEN_MOI_TRUONG',
  },
  {
    name: 'BarIngredients',
    nameLocal: translations(utilMessages.NGUYEN_LIEU_PHA_CHE),
    path: '/market?tags=NGUYEN_LIEU_PHA_CHE',
  },
  {
    name: 'BakingIngredients',
    nameLocal: translations(utilMessages.NGUYEN_LIEU_LAM_BANH),
    path: '/market?tags=NGUYEN_LIEU_LAM_BANH',
  },
  {
    name: 'AjinomotoProducts',
    nameLocal: translations(utilMessages.AJINOMOTO_GIA_TOT),
    path: '/market?tags=AJINOMOTO_GIA_TOT',
  },
  {
    name: 'SeafoodProducts',
    nameLocal: translations(utilMessages.HAI_SAN),
    path: '/market?tags=HAI_SAN',
  },
  {
    name: 'MeatProducts',
    nameLocal: translations(utilMessages.HEO_BO_GIA_SUC),
    path: '/market?tags=HEO_BO_GIA_SUC',
  },
  {
    name: 'PoultryProducts',
    nameLocal: translations(utilMessages.GA_VIT_GIA_CAM),
    path: '/market?tags=GA_VIT_GIA_CAM',
  },
  {
    name: 'DaLatProducts',
    nameLocal: translations(utilMessages.RAU_CU_QUA_DA_LAT),
    path: '/market?tags=RAU_CU_QUA_DA_LAT',
  },
  {
    name: 'OurOffer',
    nameLocal: translations(utilMessages.UU_DAI),
    path: '/market?tags=UU_DAI',
  },
  {
    name: 'ShouldTry',
    nameLocal: translations(utilMessages.TOP_PICK),
    path: '/market?tags=TOP_PICK',
  },
  {
    name: 'BestChoice',
    nameLocal: translations(utilMessages.BEST_CHOICE),
    path: '/market?tags=BEST_CHOICE',
  },
  {
    name: 'GyomuSuper',
    nameLocal: translations(utilMessages.GYOMU_SUPER),
    path: '/market?tags=GYOMU_SUPER',
  },
  {
    name: 'VietnameseRestaurants',
    nameLocal: translations(utilMessages.NHA_HANG_VIET),
    path: '/market?tags=NHA_HANG_VIET',
  },
  {
    name: 'WesternRestaurants',
    nameLocal: translations(utilMessages.NHA_HANG_TAY),
    path: '/market?tags=NHA_HANG_TAY',
  },
  {
    name: 'JapaneseRestaurants',
    nameLocal: translations(utilMessages.NHA_HANG_NHAT_BAN),
    path: '/market?tags=NHA_HANG_NHAT_BAN',
  },
  {
    name: 'KoreanRestaurants',
    nameLocal: translations(utilMessages.QUAN_AN_HAN_QUOC),
    path: '/market?tags=QUAN_AN_HAN_QUOC',
  },
  {
    name: 'ChineseRestaurants',
    nameLocal: translations(utilMessages.NHA_HANG_TRUNG_HOA),
    path: '/market?tags=NHA_HANG_TRUNG_HOA',
  },
  {
    name: 'ThaiRestaurants',
    nameLocal: translations(utilMessages.QUAN_AN_THAI_LAN),
    path: '/market?tags=QUAN_AN_THAI_LAN',
  },
  {
    name: 'VegetarianRestaurants',
    nameLocal: translations(utilMessages.NHA_HANG_CHAY),
    path: '/market?tags=NHA_HANG_CHAY',
  },
  {
    name: 'BarPubClub',
    nameLocal: translations(utilMessages.DANH_CHO_BAR_PUB_CLUB),
    path: '/market?tags=DANH_CHO_BAR_PUB_CLUB',
  },
  {
    name: 'Hotels',
    nameLocal: translations(utilMessages.DANH_CHO_KHACH_SAN),
    path: '/market?tags=DANH_CHO_KHACH_SAN',
  },
  {
    name: 'ConvenienceStores',
    nameLocal: translations(utilMessages.CUA_HANG_TIEN_LOI),
    path: '/market?tags=CUA_HANG_TIEN_LOI',
  },
  {
    name: 'Supermarkets',
    nameLocal: translations(utilMessages.DANH_CHO_SIEU_THI),
    path: '/market?tags=DANH_CHO_SIEU_THI',
  },
  {
    name: 'RetailStores',
    nameLocal: translations(utilMessages.CUA_HANG_BAN_LE),
    path: '/market?tags=CUA_HANG_BAN_LE',
  },
  {
    name: 'GroceryStores',
    nameLocal: translations(utilMessages.CUA_HANG_TAP_HOA),
    path: '/market?tags=CUA_HANG_TAP_HOA',
  },
  {
    name: 'OnlineStores',
    nameLocal: translations(utilMessages.CUA_HANG_TRUC_TUYEN),
    path: '/market?tags=CUA_HANG_TRUC_TUYEN',
  },
  {
    name: 'Cafeterias',
    nameLocal: translations(utilMessages.CATERING_CANTEEN_CAN_TIN),
    path: '/market?tags=CATERING_CANTEEN_CAN_TIN',
  },
  {
    name: 'Factories',
    nameLocal: translations(utilMessages.NHA_MAY_XI_NGHIEP),
    path: '/market?tags=NHA_MAY_XI_NGHIEP',
  },
  {
    name: 'Schools',
    nameLocal: translations(utilMessages.TRUONG_HOC),
    path: '/market?tags=TRUONG_HOC',
  },
  {
    name: 'Hospitals',
    nameLocal: translations(utilMessages.BENH_VIEN),
    path: '/market?tags=BENH_VIEN',
  },
  {
    name: 'Offices',
    nameLocal: translations(utilMessages.VAN_PHONG),
    path: '/market?tags=VAN_PHONG',
  },
  {
    name: 'Gyms',
    nameLocal: translations(utilMessages.PHONG_TAP_GYM),
    path: '/market?tags=PHONG_TAP_GYM',
  },
  {
    name: 'EventCenters',
    nameLocal: translations(utilMessages.TRUNG_TAM_SU_KIEN),
    path: '/market?tags=TRUNG_TAM_SU_KIEN',
  },
  {
    name: 'EateryWithDrinks',
    nameLocal: translations(utilMessages.AN_NHAU),
    path: '/market?tags=AN_NHAU',
  },
  {
    name: 'VietnameseEateries',
    nameLocal: translations(utilMessages.QUAN_AN_VIET),
    path: '/market?tags=QUAN_AN_VIET',
  },
  {
    name: 'PremiumProducts',
    nameLocal: translations(utilMessages.CAO_CAP),
    path: '/market?tags=CAO_CAP',
  },
  {
    name: 'ImportedProducts',
    nameLocal: translations(utilMessages.NHAP_KHAU),
    path: '/market?tags=NHAP_KHAU',
  },
  {
    name: 'DecorationFlowers',
    nameLocal: translations(utilMessages.HOA_TRANG_TRI),
    path: '/market?tags=HOA_TRANG_TRI',
  },
  {
    name: 'WesternVietnamProducts',
    nameLocal: translations(utilMessages.RAU_CU_QUA_MIEN_TAY),
    path: '/market?tags=RAU_CU_QUA_MIEN_TAY',
  },
  {
    name: 'Favorite',
    nameLocal: translations(messages.favorite),
    path: '/favorite',
  },
  {
    name: 'FavoriteCombo',
    nameLocal: translations(messages.combo),
    path: '/favorite?tags=SUPER_COMBO',
    parentName: 'Favorite',
    type: 'favorite',
  },
  {
    name: 'Account',
    nameLocal: translations(messages.account),
    path: '/account-info',
  },
  {
    name: 'Company',
    nameLocal: translations(messages.companyInfo),
    path: '/company-info',
  },
  {
    name: 'Store',
    nameLocal: translations(messages.store),
    path: '/store-info',
  },
  {
    name: 'OverView',
    nameLocal: translations(messages.overview),
    path: '/overview',
  },
  {
    name: 'ItemAnalysis',
    nameLocal: translations(messages.analysis),
    path: '/item-analysis',
  },
  {
    name: 'Notifications',
    nameLocal: translations(messages.notifications),
    path: '/notifications',
  },
  {
    name: 'Cart',
    nameLocal: translations(messages.cart),
    path: '/checkout',
  },
  {
    name: 'Order',
    nameLocal: translations(messages.orderHistory),
    path: '/orders',
    childrenName: 'OrderDetail',
  },
  {
    name: 'OrderDetail',
    nameLocal: translations(messages.orderDetail),
    path: '',
  },
  {
    name: 'OrderPendingApproval',
    nameLocal: translations(messages.orderPendingApproval),
    path: '/orders-pending-approval?supplier=',
    childrenName: 'OrderPendingApprovalDetail',
  },
  {
    name: 'OrderPendingApprovalDetail',
    nameLocal: translations(messages.orderDetail),
    path: '',
  },
  {
    name: 'UserManagement',
    nameLocal: translations(messages.usersManagement),
    path: '/user-management',
  },
  {
    name: 'UserInformation',
    nameLocal: translations(messages.usersManagement),
    path: '/user-details',
    childrenName: 'UserDetail',
  },
  {
    name: 'UserDetail',
    nameLocal: translations(messages.userInformation),
    path: '',
  },
  {
    name: 'GroupManagement',
    nameLocal: translations(messages.groupsManagement),
    path: '/group-management',
  },
  {
    name: 'GroupInformation',
    nameLocal: translations(messages.groupsManagement),
    path: '/groups',
    childrenName: 'GroupDetail',
  },
  {
    name: 'GroupDetail',
    nameLocal: translations(messages.groupsInformation),
    path: '',
  },
];
export default routes;
