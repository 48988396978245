import { Avatar, List, Skeleton, Tag } from 'antd';
import React, { useEffect, useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect } from 'react-redux';
import { RouteComponentProps, RouteProps, withRouter } from 'react-router-dom';
import { Dispatch, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { ApplicationRootState } from 'types';
import generateProductSlug from 'utils/generateProductSlug';
import * as qs from 'utils/queryString';
import { setSearchProductResults } from '../../containers/MainLayout/actions';
import makeSelectMainLayout, { selectSearchProductResults } from '../../containers/MainLayout/selectors';
import { MainLayoutState } from '../../containers/MainLayout/types';
import shuffle from 'lodash/shuffle';
import translations from 'translations';
import message from './messages';
import subCategoriesImage from './SubCateImg';
interface IProps {
  keyword: string;
  onCloseDropdown: () => void;
}

interface StateProps {
  searchResultList: any;
  mainLayout: MainLayoutState;
}
interface IDispatchProps {
  setSearchProductResults: (payload: any) => void;
}
type Props = IDispatchProps & IProps & StateProps & RouteComponentProps & RouteProps;

const SearchWithDropDownContainer = styled.div`
  border-radius: 7px;
  background: #ffffff;
  position: absolute;
  top: 63px;
  width: 100%;
  max-height: 400px;
  overflow: auto;
  padding: 16px;
  box-shadow: 0px 2px 7.3px 0px rgba(0, 0, 0, 0.2);
  .ant-list-item {
    border-radius: 6px;
    cursor: pointer;
    .ant-list-item-meta-title {
      font-weight: 400;
      color: #424242 !important;
      margin-bottom: 0px !important;
    }
    .ant-list-item-meta {
      margin: 0 4px;
      align-items: center;
    }
  }
  .ant-list-item:hover {
    background: #f1f1f1;
  }
  @media (max-width: 767px) {
    top: 45px;
  }
`;
const PopularSearch = styled.div`
  border-radius: 7px;
  background: #ffffff;
  position: absolute;
  top: 63px;
  padding: 16px;
  box-shadow: 0px 2px 7.3px 0px rgba(0, 0, 0, 0.2);
  @media (max-width: 992px) {
    width: 100%;
  }
  @media (max-width: 767px) {
    top: 45px;
  }
`;
const PopularSearchTitle = styled.h2`
  color: #242527;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 12px;
`;
const PopularSearchList = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 130px);
  gap: 8px;
  @media (max-width: 992px) {
    width: 100%;
    overflow-x: auto;
  }
`;
const PopularTag = styled(Tag)`
  white-space: break-spaces;
  padding: 4px;
  margin: 0;
  font-size: 13px;
  line-height: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  background: #ffffff;
  font-weight: 400;
  &:hover {
    color: #4b9c63;
    border-color: #4b9c63;
  }
`;
const SubCatText = styled.div``;
const SubCatImg = styled(Avatar)`
  flex-shrink: 0;
`;
const SearchWithDropDown: React.FC<Props> = (props) => {
  const { keyword, setSearchProductResults, onCloseDropdown, searchResultList, location, history, mainLayout } = props;
  const lang = localStorage.getItem('lang') || 'vi';

  const loadMoreData = () => {
    if (searchResultList.loading) {
      return;
    }
    const query = qs.parse(location.search);
    setSearchProductResults({
      query: {
        filter: {
          query: keyword,
          categoryName: query?.filter?.categoryName,
        },
      },
      clearPreviousData: false,
    });
  };
  const popularSearchData = useMemo(() => {
    if (!mainLayout.categories?.length) return [];
    const subCategory = mainLayout.categories.reduce((acc, item) => {
      const subCategories = item.children.reduce((acc, subCat) => {
        if (!subCat.name.includes('Other')) {
          return [...acc, subCat];
        }
        return acc;
      }, []);
      return [...acc, ...subCategories];
    }, []);
    const result = shuffle(subCategory);
    return result.slice(0, 8).map((item) => ({
      ...item,
      nameImg: item.name.split('/')[1].replace(/[ &]/g, ''),
    }));
  }, [mainLayout.categories]);
  useEffect(() => {
    const query = qs.parse(location.search);
    if (query && keyword && !searchResultList?.data?.length) {
      setSearchProductResults({
        query: query,
        clearPreviousData: true,
      });
    }
  }, []);

  if (!keyword && !searchResultList.data.length) {
    return (
      <PopularSearch>
        <PopularSearchTitle>{translations(message.popularSearches)}</PopularSearchTitle>
        <PopularSearchList>
          {popularSearchData.map((item) => (
            <>
              <PopularTag
                key={item.name}
                color={item.color}
                onClick={() => {
                  history.push(`/market?category=${encodeURIComponent(item.name)}`);
                  onCloseDropdown();
                }}
              >
                <SubCatImg
                  shape="square"
                  size={40}
                  src={<img src={subCategoriesImage[item.nameImg]} alt={`Icon-${item.nameImg}`} loading="lazy" />}
                />
                <SubCatText>{item.nameLocal}</SubCatText>
              </PopularTag>
            </>
          ))}
        </PopularSearchList>
      </PopularSearch>
    );
  }
  return (
    <SearchWithDropDownContainer id="scrollableDiv">
      <InfiniteScroll
        dataLength={searchResultList.data.length}
        next={loadMoreData}
        hasMore={searchResultList.data.length < searchResultList.totalResults}
        loader={<Skeleton avatar={{ shape: 'square' }} paragraph={{ rows: 0 }} title={{ width: '100%' }} active />}
        scrollableTarget="scrollableDiv"
      >
        <List
          dataSource={searchResultList.data}
          renderItem={(item: any) => (
            <List.Item
              key={item.id}
              onClick={() => {
                history.push(`/products/${generateProductSlug(item.name, item.supplierInternalCode, lang)}`);
                onCloseDropdown();
              }}
            >
              <List.Item.Meta avatar={<Avatar shape="square" size={40} src={item.imageUrl} />} title={item.name} />
            </List.Item>
          )}
          split={false}
          loading={searchResultList.loading}
        />
      </InfiniteScroll>
    </SearchWithDropDownContainer>
  );
};
const mapStateToProps = createStructuredSelector<ApplicationRootState, StateProps>({
  searchResultList: selectSearchProductResults(),
  mainLayout: makeSelectMainLayout(),
});
const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
  return {
    setSearchProductResults: (payload: any) => {
      dispatch(setSearchProductResults.request(payload));
    },
  };
};
export default compose(
  connect((state) => state),
  connect(mapStateToProps, mapDispatchToProps),
)(withRouter(SearchWithDropDown));
