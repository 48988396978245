import * as Sentry from '@sentry/browser';

import { FormattedMessage } from 'react-intl';
import React from 'react';
import messages from 'utils/messages';
import translations from 'translations';

export class ErrorBoundary extends React.Component {
  public state = { error: null, errorInfo: null };
  public componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });

    if (process.env.NODE_ENV === 'production') {
      Sentry.withScope(scope => {
        Object.keys(errorInfo).forEach(key => {
          scope.setExtra(key, errorInfo[key]);
        });
        Sentry.captureException(error);
      });
    }
  }
  public render() {
    const { error, errorInfo }: { error: any; errorInfo: any } = this.state;
    const lang = window.localStorage.getItem('lang') || 'vi';
    if (error || errorInfo) {
      return (
        <div>
          <h2>
            <FormattedMessage {...messages.sentryError} />
          </h2>
          <a
            onClick={() =>
              Sentry.showReportDialog({
                lang: lang,
                title: translations(messages.sentryTitle),
                subtitle: translations(messages.sentrySubtitle),
                subtitle2: translations(messages.sentrySubtitle2),
                labelName: translations(messages.sentryLabelName),
                labelEmail: translations(messages.sentryLabelEmail),
                labelComments: translations(messages.sentryLabelComments),
                labelClose: translations(messages.sentryLabelClose),
                labelSubmit: translations(messages.sentryLabelSubmit),
                errorGeneric: translations(messages.sentryErrorGeneric),
                errorFormEntry: translations(messages.sentryErrorFormEntry),
                successMessage: translations(messages.sentrySuccessMessage),
              })
            }
          >
            <FormattedMessage {...messages.sentryReportFeedback} />
          </a>
        </div>
      );
    }
    return this.props.children;
  }
}
