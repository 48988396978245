import React, { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { createStructuredSelector } from 'reselect';
import selectHomePage from './selectors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import reducer from './reducer';
import saga from './saga';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import Widgets from 'components/Widgets';
import { Helmet } from 'react-helmet';
import translations from 'translations';
import messages from './messages';
import { ContainerState } from './types';
import { ApplicationRootState } from 'types';
import { Checkbox, Modal } from 'antd';
import styled from 'styles/styled-components';
import { RouteComponentProps } from 'react-router-dom';
import useLocalStorage from 'hooks/useLocalStorage';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Buyer, GoToScreenPopup, Store } from 'types/schema';
import { IKeyTreatments } from '@splitsoftware/splitio-redux/lib/types';
import useSessionStorage, { SessionStorageKey } from 'hooks/useSessionStorage';
import { TreatmentNames } from 'split/constants';
import { selectBuyer, selectStore, selectPromotionDataPopup } from 'containers/MainLayout/selectors';
import { selectTreatmentValueByName } from 'split/selectors';
import KamereoLogo from '../../images/KamereoLogo.png';
import renderWithWebDomain from 'utils/renderWithWebDomain';
import organizationDataMarkup from './organizationDataMarkup.json';
import NewCustomerPopupVn from './POPUP-VN.png';
import NewCustomerPopupEn from './POPUP-EN.png';
import isGoogleBot from 'utils/urls/isGoogleBot';

interface IStateProps {
  homePage: ContainerState;
  homepagePopupTreatment: string | IKeyTreatments;
  buyer: Buyer;
  store: Store;
  promotionDataPopup: any;
}

const ModalContainer = styled(Modal)`
  .ant-modal-content {
    background: transparent;
    box-shadow: none;
  }

  .ant-modal-close {
    right: 24px;
    top: -24px;
  }
  button {
    width: 27px !important;
    height: 27px !important;
    .ant-modal-close-x {
      width: 27px;
      height: 27px;
      border-radius: 50%;
      background: #fafafa;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

const PopUpImageContainer = styled.div`
  position: relative;
  cursor: pointer;
  width: 100%;
  img {
    width: 100%;
    border-radius: 40px;
  }
`;

const HidePopupActionContainer = styled.div`
  margin-top: 16px;
  text-align: center;
  .ant-checkbox-wrapper {
    color: #ffffff;
  }
  .ant-checkbox + span {
    padding-left: 16px;
  }
`;

const PopupContainer = styled(Modal)`
  min-width: 320px;

  .ant-modal-body {
    padding: 0px;
  }
  .ant-modal-content {
    padding: 0px !important;
  }
  width: 60% !important;
  @media (max-width: 991px) {
    width: 90% !important;
  }
`;

const PromotionPopupContainer = styled.div`
  position: relative;
  overflow: auto;
  width: 100%;
  padding-top: 60%;
  @media (max-width: 991px) {
    padding-top: 110%;
  }
`;
const PromotionPopupWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 32px;
  @media (max-width: 991px) {
    padding: 16px;
  }
`;
const PromotionPopupTitle = styled.h2`
  font-size: 23px;
  margin-bottom: 16px;
`;

const PromotionPopupContent = styled.div``;

type Props = IStateProps & RouteComponentProps;

interface TBlockHomePagePopup {
  [key: string]: boolean;
}
export const IS_USE_STATIC_POPUP = false;

const HomePage: FC<Props> = (props) => {
  const {
    history,
    homePage: { homePagePopup, widgets },
    homepagePopupTreatment,
    promotionDataPopup,
  } = props;
  const isLogin = window.localStorage.getItem('token');
  const lang = window.localStorage.getItem('lang') || 'vi';
  const [blockHomePagePopup, setBlockHomePagePopup] = useLocalStorage<TBlockHomePagePopup>('blockHomePagePopup', {});
  const [checkOpenedPopup, setCheckOpenedPopup] = useSessionStorage(SessionStorageKey.HOMEPAGE_POPUP, false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isPreferentialPopupVisible, setPreferentialPopupVisible] = useState(false);
  const popup = useMemo(() => {
    if (homePagePopup && 'key' in homePagePopup) {
      return homePagePopup;
    }
    return undefined;
  }, [homePagePopup]);

  useEffect(() => {
    if (
      !isGoogleBot() &&
      ((popup && !blockHomePagePopup[popup.key] && !checkOpenedPopup && homepagePopupTreatment === 'on') ||
        (!isLogin && !checkOpenedPopup && promotionDataPopup && IS_USE_STATIC_POPUP))
    ) {
      setIsPopupVisible(true);
    }
  }, [blockHomePagePopup, popup, checkOpenedPopup, homepagePopupTreatment, isLogin, promotionDataPopup]);

  const handleHompagePopupDisplayStatus = useCallback(
    (e: CheckboxChangeEvent) => {
      if (popup) {
        setBlockHomePagePopup({ [popup.key]: e.target.checked });
      }
    },
    [popup, setBlockHomePagePopup],
  );

  const HanleNavigationHomepagePopup = useCallback(() => {
    if (popup && (isLogin || !IS_USE_STATIC_POPUP)) {
      history.push(`/market?tags=${((popup as GoToScreenPopup).screen.filter.tags || '').toString()}`);
    } else {
      setIsPopupVisible(false);
      setPreferentialPopupVisible(true);
    }
    setCheckOpenedPopup(true);
  }, [popup, setCheckOpenedPopup, isLogin]);

  const handleCancel = useCallback(() => {
    setIsPopupVisible(false);
    setCheckOpenedPopup(true);
  }, []);

  const popupImage = useMemo(() => {
    if (isLogin || !IS_USE_STATIC_POPUP) {
      return popup && popup.imageUrl;
    }
    return lang === 'vi' ? NewCustomerPopupVn : NewCustomerPopupEn;
  }, [popup, lang, isLogin]);
  return (
    <Fragment>
      <Helmet>
        <title>KAMEREO - Đối tác cung cấp thực phẩm uy tín</title>
        <meta property="og:url" content={renderWithWebDomain(process.env.APP_ENV)} />
        <link rel="canonical" href={renderWithWebDomain(process.env.APP_ENV)} />
        <link rel="alternate" href={renderWithWebDomain(process.env.APP_ENV)} />
        <meta
          name="description"
          content="Cung cấp nguyên liệu, vật dụng cho Nhà Hàng, Cà Phê, Bar, Khách Sạn, Siêu Thị,... Nguồn hàng phong phú, ổn định. Giao Hàng Tận Nơi. Có giấy chứng nhận VSATTP"
        />
        <meta property="og:title" content="KAMEREO - Đối tác cung cấp thực phẩm uy tín" />
        <meta property="og:type" content="website" />
        <meta property="og:image:url" content={renderWithWebDomain(process.env.APP_ENV, KamereoLogo)} />
        <meta property="og:image:secure_url" content={renderWithWebDomain(process.env.APP_ENV, KamereoLogo)} />
        <meta property="og:image:width" content="768" />
        <meta property="og:image:height" content="768" />
        <meta property="og:image:alt" content={translations(messages.kamereo)} />
        <script type="application/ld+json">{JSON.stringify(organizationDataMarkup)}</script>
      </Helmet>
      <Widgets widgets={widgets} />
      <ModalContainer width={548} open={isPopupVisible} footer={null} centered onCancel={handleCancel}>
        <PopUpImageContainer onClick={HanleNavigationHomepagePopup}>
          <img src={popupImage} />
        </PopUpImageContainer>
        <HidePopupActionContainer>
          <Checkbox onChange={handleHompagePopupDisplayStatus}>{translations(messages.dontShowThisAgain)}</Checkbox>
        </HidePopupActionContainer>
      </ModalContainer>
      <PopupContainer
        open={isPreferentialPopupVisible}
        onCancel={() => setPreferentialPopupVisible(false)}
        footer={null}
        centered={true}
      >
        {promotionDataPopup && promotionDataPopup.content && (
          <PromotionPopupContainer>
            <PromotionPopupWrapper>
              <PromotionPopupTitle>{promotionDataPopup?.title?.rendered}</PromotionPopupTitle>
              <PromotionPopupContent dangerouslySetInnerHTML={{ __html: promotionDataPopup?.content?.rendered }} />
            </PromotionPopupWrapper>
          </PromotionPopupContainer>
        )}
      </PopupContainer>
    </Fragment>
  );
};

const mapStateToProps = createStructuredSelector<ApplicationRootState, IStateProps>({
  homePage: selectHomePage(),
  homepagePopupTreatment: selectTreatmentValueByName(TreatmentNames.BUYER_WEB_HOME_PAGE_POPUP),
  buyer: selectBuyer(),
  store: selectStore(),
  promotionDataPopup: selectPromotionDataPopup(),
});

const withConnect = connect(mapStateToProps);

const withReducer = injectReducer({
  key: 'homePage',
  reducer: reducer,
});

const withSaga = injectSaga({ key: 'homePage', saga: saga });

export default compose(withConnect, withReducer, withSaga)(HomePage);
