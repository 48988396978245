import { Button, Dropdown, Menu, Tooltip, MenuProps } from 'antd';
import LoadingIndicator from 'components/LoadingIndicator';
import { Viewport } from 'components/ViewportProvider';
import MainCategoryNavMobile from 'containers/MainCategoryMobile';
import { pick, debounce } from 'lodash';
import React, { FC, Suspense, lazy, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import translations from 'translations';
import { Category, Language, Store } from 'types/schema';
import { PHONE_NUMBER } from 'utils/constants';
import getSelectedCategory from 'utils/getSelectedCategory';
import messages from 'utils/messages';
import { viewPermissions } from 'utils/permissionMap';
import { parse, stringify } from 'utils/queryString';
import qs from 'qs';
import renderCategoryName, { FilterableKeys } from 'utils/renderCategoryName';
import CategoryNav from '../../containers/CategoryNav';
import SVGIcon from '../SVGIcon/SVGIcon';
import AmericanFlag from './AmericanFlag.svg';
import ArrowDownSwitchStore from './ArrowDownSwitchStore.svg';
import Cart from './CartV3.svg';
import CategoryIcon from './CategoryIcon.svg';
import DownloadAndroidApp from './DownloadAndroidApp.svg';
import DownloadAndroidAppVi from './DownloadAndroidAppVi.svg';
import DownloadIOSApp from './DownloadIOSApp.svg';
import DownloadIOSAppVi from './DownloadIOSAppVi.svg';
import FavoriteIcon from './FavoriteV3.svg';
import HamburgerMenu from './HamburgerMenu.svg';
import Kamereo from './Kamereo.svg';
import KamereoV2 from './KamereoV2.svg';
import Location from './Location.svg';
import Mobile from './Mobile.svg';
import Phone from './Phone.svg';
import TopNavCategory from './TopNavCategory';
import {
  AntdInput,
  CustomSearchInput,
  EnvIndicator,
  FlexContainer,
  InputContainer,
  KamereoLogo,
  LeftNav,
  LinkText,
  MenuItem,
  MenuItemLink,
  MenuLabel,
  MiddleNav,
  ProfileInfoContainer,
  RightNav,
  SignInSignUpWrapper,
  SignUpButton,
  SwitchStoreContainer,
  SwitchStoreMobileContainer,
  SwitchStoreText,
  TopNavBadge,
  TopNavButtonHiddenOnMobile,
  TopNavContent,
  TopNavLinkItem,
  TopNavMenuItem,
  TopNavTopContent,
  TopNavTopContentLeft,
  TopNavTopContentRight,
  TopNavTopContentWrapper,
  TopNavWrapper,
} from './TopNavComponents';
import VietnamFlag from './VietnamFlag.svg';
import WebQRCode from './WebQRcode.png';
import topNavMessage from './messages';
import './topNav.css';
import { SearchOutlined } from '@ant-design/icons';
import VisuallyHidden from 'components/VisuallyHidden';
import SearchWithDropDown from 'components/MainNav/SearchWithDropDown';
import BlogDropDown from './BlogDropDown';
import KamereoInfo from './KamereoInfo';

interface ITopNavProps extends RouteComponentProps {
  opened: boolean;
  user: any;
  store: Store;
  cart: { cartSet: any[] };
  permissions: string[];
  onToggle: () => void | undefined;
  onSignout: () => void | undefined;
  onSetLang: (data: any) => void | undefined;
  categories: Category[];
  hasUnreadNotification: boolean;
  setSearchProductResults: (payload: any) => void;
  clearSearchProductResults: () => void;
}

type FlagByLang = {
  [lang: string]: FlagInfo;
};

type FlagInfo = {
  base64: string;
  alt: string;
};

const flagByLang: FlagByLang = {
  vi: {
    base64: VietnamFlag,
    alt: 'Flag of Vietnam',
  },
  en: {
    base64: AmericanFlag,
    alt: 'Flag of USA',
  },
};

export const CategoryIconWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 10px 16px;
  font-size: 15px;
  color: #424242;
  border: 1px solid rgba(189, 189, 189, 0.6);
  height: fit-content;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background: #fafafa;
    font-weight: 500;
    border-color: rgba(189, 189, 189, 0.6);
  }
  ${({ isActive }) =>
    isActive &&
    `
    color: #4caf50;
    border-color: #4CAF50 !important;
    background: #fafafa;
    div div svg {
      filter: invert(53%) sepia(9%) saturate(3454%) hue-rotate(73deg) brightness(99%) contrast(68%);
    }
  `}
`;
export const LanguageItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
const HeaderIconWrapper = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: #424242;
  &:hover {
    background: #fafafa;
  }
  &.active {
    background: #fafafa;
    color: #4caf50;
  }
`;
const SearchWithDropDownComponent = styled(SearchWithDropDown)`
  position: absolute;
  top: 50px;
  left: 0px;
`;
const debounceInput = debounce((cb) => {
  cb();
}, 300);
const SideNavMobileComponent = lazy(() => import('./SideNavMobile'));
const TopNav: FC<ITopNavProps> = (props) => {
  const {
    onSignout,
    onSetLang,
    user,
    cart,
    store,
    permissions: userPermissions,
    history,
    location,
    categories,
    setSearchProductResults,
    clearSearchProductResults,
  } = props;
  const lang = window.localStorage.getItem('lang') || 'vi';
  const loggedIn = !!localStorage.getItem('token');
  const onChangeLang = (langSwitcher: Language) => () => onSetLang(langSwitcher);
  const category = getSelectedCategory(categories, location);
  const search = parse(location.search);
  const fromQuery = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [isOpenSideNav, setIsOpenSideNav] = useState(false);
  const [isOpenCategory, setIsOpenCategory] = useState(false);

  const canAccessView = (path: string): boolean => {
    const permissions: string[] = viewPermissions[path];
    if (permissions) {
      let permissionCounter = 0;
      for (const permission of permissions) {
        if (userPermissions.indexOf(permission) > -1) {
          permissionCounter += 1;
        }
      }

      if (permissionCounter !== permissions.length) {
        return false;
      }
    }
    return true;
  };

  const profileMenu = (
    <Menu>
      <Menu.Item key="0">
        <MenuItemLink to="/account-info">
          <MenuLabel>
            <FormattedMessage {...messages.accountSetting} />
          </MenuLabel>
        </MenuItemLink>
      </Menu.Item>
      <Menu.Item key="1">
        <MenuItemLink to="/orders">
          <MenuLabel>
            <FormattedMessage {...messages.orderHistory} />
          </MenuLabel>
        </MenuItemLink>
      </Menu.Item>
      <Menu.Item key="2">
        <MenuItemLink to="/overview">
          <MenuLabel>
            <FormattedMessage {...messages.overviewReport} />
          </MenuLabel>
        </MenuItemLink>
      </Menu.Item>
      <Menu.Item key="3">
        <MenuItemLink to="/company-info">
          <MenuLabel>
            <FormattedMessage {...messages.businessSettings} />
          </MenuLabel>
        </MenuItemLink>
      </Menu.Item>
      <Menu.Item key="4">
        <MenuItem color="#FE5043" onClick={onSignout}>
          <MenuLabel>
            <FormattedMessage {...messages.signout} />
          </MenuLabel>
        </MenuItem>
      </Menu.Item>
    </Menu>
  );

  const items: MenuProps['items'] = [
    {
      key: 1,
      label: (
        <LanguageItem onClick={onChangeLang(Language.En)}>
          <img src={AmericanFlag} alt="American flag" width="24.14" height="16" loading="lazy" />
          <div>English</div>
        </LanguageItem>
      ),
    },
    {
      key: 2,
      label: (
        <LanguageItem onClick={onChangeLang(Language.Vi)}>
          <img src={VietnamFlag} alt="VietnamFlag" width="24.14" height="16" loading="lazy" />
          <div>Vietnamese</div>
        </LanguageItem>
      ),
    },
  ];

  const [keyword, setKeyWord] = useState<string>('');
  const [tempKeyword, setTempKeyword] = useState('');
  const [isOpenSearchResultDropdown, setIsOpenSearchResultDropdown] = useState(false);
  const [isSideBarMounted, setIsSideBarMounted] = useState<boolean>(false);
  const searchInputContainer = useRef<any>(null);
  const searchInput = useRef<any>(null);
  const onChangeSearch = (event) => {
    setKeyWord(event.target.value);
    debounceInput(() => {
      if (event.target.value) {
        const query = parse(location.search);
        setSearchProductResults({
          query: {
            filter: {
              query: event.target.value,
              categoryName: query?.filter?.categoryName,
              // withexclusive: true,
            },
          },
          clearPreviousData: true,
        });
      } else {
        clearSearchProductResults();
      }
      setTempKeyword(event.target.value);
    });
  };

  const filterableKeysParams = useMemo(() => {
    return {
      ...search,
      filter: pick(search.filter, Object.values(FilterableKeys)),
    };
  }, [search, FilterableKeys]);

  const handleOnSearch = useCallback(() => {
    if (!keyword) return;
    const searchParams = stringify({
      ...filterableKeysParams,
      q: keyword || undefined,
      favorite: undefined,
      // withexclusive: true,
    });
    history.push({
      pathname: `/market`,
      search: searchParams,
    });
    setIsOpenSearchResultDropdown(false);
    searchInput?.current?.blur();
  }, [keyword, history]);

  const handleToggleSideNav = () => {
    const bodyElement = document.querySelector('body');
    if (!isOpenSideNav && bodyElement) {
      bodyElement.classList.add('hideScroll');
      if (!isSideBarMounted) {
        setIsSideBarMounted(true);
      }
    } else if (bodyElement) {
      bodyElement.classList.remove('hideScroll');
    }
    setIsOpenSideNav(!isOpenSideNav);
  };
  const handleToggleCategory = () => {
    setIsOpenCategory(!isOpenCategory);
  };

  const { base64, alt } = useMemo<FlagInfo>(() => flagByLang[lang], [lang]);

  const closeSearchResultsDropdown = (e) => {
    if (!searchInputContainer.current?.contains(e.target)) {
      setIsOpenSearchResultDropdown(false);
    }
  };
  useEffect(() => {
    /* Dispatch signout action when user click signout button from Kamereo blog */
    if (Object.keys(fromQuery).length && fromQuery.from === 'blog') {
      const action = fromQuery.action;
      switch (action) {
        case 'signout': {
          onSignout();
          break;
        }
        default:
          break;
      }
    }

    if (location.pathname === '/favorite') {
      setKeyWord('');
      setTempKeyword('');
      clearSearchProductResults();
      return;
    }
    setKeyWord(search.filter?.query || '');
    setTempKeyword(search.filter?.query || '');
    if (!search.filter?.query) {
      clearSearchProductResults();
    }
  }, [location.search]);
  useEffect(() => {
    document.addEventListener('mousedown', closeSearchResultsDropdown);
    return () => {
      document.removeEventListener('mousedown', closeSearchResultsDropdown);
    };
  }, []);
  return (
    <>
      <TopNavWrapper>
        <TopNavTopContentWrapper>
          <TopNavTopContent>
            <TopNavTopContentLeft>
              <Viewport.Consumer>
                {({ width }) => {
                  return (
                    width >= 768 && (
                      <>
                        <Tooltip
                          placement="bottomLeft"
                          title={() => {
                            return (
                              <div className="header-download__container">
                                <div className="header-download__content">
                                  <div className="header-download__left">
                                    <img src={WebQRCode} width="148" height="148" loading="lazy" />
                                  </div>
                                  <div className="header-download__right">
                                    <span>{translations(topNavMessage.scanQRorDownloadApp)}</span>
                                    <div className="mt-16">
                                      <a
                                        href="https://play.google.com/store/apps/details?id=vn.kamereo.buyer&amp;hl=en_US&amp;gl=US"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                      >
                                        <img
                                          src={lang === 'vi' ? DownloadAndroidAppVi : DownloadAndroidApp}
                                          width="112px"
                                          height="33px"
                                          alt="playstoreimage"
                                          loading="lazy"
                                        />
                                        <VisuallyHidden asComponent="span">
                                          {`${translations(topNavMessage.downloadKamereoApp)} Android`}
                                        </VisuallyHidden>
                                      </a>
                                    </div>
                                    <div>
                                      <a
                                        href="https://apps.apple.com/sa/app/kamereo/id1469505654"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                      >
                                        <img
                                          src={lang === 'vi' ? DownloadIOSAppVi : DownloadIOSApp}
                                          width="112px"
                                          height="33px"
                                          alt="applestoreimage"
                                          loading="lazy"
                                        />
                                        <VisuallyHidden asComponent="span">
                                          {`${translations(topNavMessage.downloadKamereoApp)} iOS`}
                                        </VisuallyHidden>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }}
                          overlayClassName="top-nav-tooltip-container"
                        >
                          <FlexContainer>
                            <SVGIcon
                              renderAsImg={false}
                              src={Mobile}
                              beforeInjection={(svg) => {
                                svg.setAttribute('style', 'width: 10px');
                              }}
                            />
                            <p>{translations(topNavMessage.downloadKamereoApp)}</p>
                          </FlexContainer>
                        </Tooltip>
                      </>
                    )
                  );
                }}
              </Viewport.Consumer>
              <FlexContainer>
                <SVGIcon
                  renderAsImg={false}
                  src={Phone}
                  beforeInjection={(svg) => {
                    svg.setAttribute('style', 'width: 14px');
                  }}
                />
                <a href={PHONE_NUMBER}>0812 46 37 27</a>
              </FlexContainer>
            </TopNavTopContentLeft>
            <TopNavTopContentRight>
              <Viewport.Consumer>
                {({ width }) => {
                  return (
                    width >= 576 && (
                      <>
                        <div className="top-nav-item">
                          <KamereoInfo />
                        </div>
                        <div className="top-nav-item">
                          <BlogDropDown />
                        </div>
                        {loggedIn && (
                          <div className="top-nav-item">
                            <TopNavLinkItem to="/notifications" className="no-padding">
                              <FormattedMessage {...messages.notification} />
                            </TopNavLinkItem>
                          </div>
                        )}
                      </>
                    )
                  );
                }}
              </Viewport.Consumer>
              <Dropdown
                menu={{ items: items, selectable: true, defaultSelectedKeys: lang === 'en' ? ['1'] : ['2'] }}
                placement="bottomRight"
              >
                <div onClick={(e) => e.preventDefault()} className="top-nav-item language-dropdown">
                  <img src={base64} alt={alt} width="24.14px" height="16px" />
                </div>
              </Dropdown>
              {loggedIn && (
                <>
                  <Link to="/change-store">
                    <SwitchStoreContainer>
                      <SVGIcon renderAsImg={false} src={Location} />
                      <SwitchStoreText>{store.name}</SwitchStoreText>
                      <SVGIcon renderAsImg={false} src={ArrowDownSwitchStore} />
                    </SwitchStoreContainer>
                  </Link>
                </>
              )}
            </TopNavTopContentRight>
          </TopNavTopContent>
        </TopNavTopContentWrapper>
        <TopNavContent>
          <Viewport.Consumer>
            {({ width }) => (
              <LeftNav>
                {width > 767 && (
                  <TopNavLinkItem to="/" isLogoNav={true}>
                    <KamereoLogo src={Kamereo} alt="KAMEREO - Đối tác cung cấp thực phẩm uy tín" />
                    {location.pathname === '/' && (
                      <VisuallyHidden asComponent="h1">KAMEREO - Đối tác cung cấp thực phẩm uy tín</VisuallyHidden>
                    )}
                  </TopNavLinkItem>
                )}
                {width <= 767 && (
                  <TopNavLinkItem to="/" isLogoNav={true}>
                    <KamereoLogo src={KamereoV2} alt="KAMEREO - Đối tác cung cấp thực phẩm uy tín" />
                    {location.pathname === '/' && (
                      <VisuallyHidden asComponent="h1">KAMEREO - Đối tác cung cấp thực phẩm uy tín</VisuallyHidden>
                    )}
                  </TopNavLinkItem>
                )}
                {width > 1024 && (
                  <CategoryIconWrapper onClick={handleToggleCategory} isActive={isOpenCategory}>
                    <SVGIcon src={CategoryIcon} imgAttr={{ width: '18px', height: '18px' }} />
                    <FormattedMessage {...messages.category} />
                  </CategoryIconWrapper>
                )}
                <>{process.env.APP_ENV === 'production' ? '' : <EnvIndicator>QA</EnvIndicator>}</>
              </LeftNav>
            )}
          </Viewport.Consumer>
          <Viewport.Consumer>
            {({ width }) => {
              if (loggedIn) {
                return (
                  <>
                    <MiddleNav>
                      {(width > 1024 || (width <= 1024 && !['/market', '/favorite'].includes(location.pathname))) && (
                        <InputContainer ref={searchInputContainer}>
                          <CustomSearchInput
                            ref={searchInput}
                            onChange={onChangeSearch}
                            onPressEnter={handleOnSearch}
                            value={keyword}
                            placeholder={`${translations(messages.search)} ${translations(
                              messages.in,
                            )} ${renderCategoryName(category || undefined, search, 'KAMEREO')}`}
                            size="default"
                            suffix={
                              <SearchOutlined
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleOnSearch();
                                }}
                                style={{ fontSize: '21px', color: 'rgb(67, 160, 71)', cursor: 'pointer' }}
                              />
                            }
                          >
                            <AntdInput onFocus={() => setIsOpenSearchResultDropdown(true)} />
                          </CustomSearchInput>
                          {isOpenSearchResultDropdown && (
                            <SearchWithDropDownComponent
                              keyword={tempKeyword}
                              onCloseDropdown={() => setIsOpenSearchResultDropdown(false)}
                            />
                          )}
                        </InputContainer>
                      )}
                      {['/market', '/favorite'].includes(location.pathname) && width < 1025 && (
                        <MainCategoryNavMobile />
                      )}
                    </MiddleNav>
                    <RightNav className="login">
                      {width > 1024 && (
                        <>
                          <LinkText to="/favorite">
                            <HeaderIconWrapper className={['/favorite'].includes(location.pathname) ? 'active' : ''}>
                              <SVGIcon src={FavoriteIcon} imgAttr={{ width: '27px', height: '24px' }} />
                            </HeaderIconWrapper>
                          </LinkText>
                        </>
                      )}
                      {width <= 767 && (
                        <SwitchStoreMobileContainer>
                          <Link to="/change-store">
                            <SVGIcon src={Location} renderAsImg={false} />
                            <SwitchStoreText>{store.name}</SwitchStoreText>
                          </Link>
                        </SwitchStoreMobileContainer>
                      )}
                      {width <= 767 && <SVGIcon src={HamburgerMenu} onClick={handleToggleSideNav} />}
                      {width > 1024 && (
                        <>
                          {canAccessView('/checkout/step/1') && (
                            <TopNavLinkItem to="/checkout/step/1" className="no-padding">
                              <HeaderIconWrapper
                                className={location.pathname.split('/').includes('checkout') ? 'active' : ''}
                              >
                                <TopNavBadge>
                                  {cart.cartSet.map((cartSet) => cartSet.items.length).reduce((a, b) => a + b, 0)}
                                </TopNavBadge>
                                <SVGIcon
                                  src={Cart}
                                  className="relative-position"
                                  imgAttr={{ width: '24px', height: '24px' }}
                                />
                              </HeaderIconWrapper>
                            </TopNavLinkItem>
                          )}
                        </>
                      )}
                      {width > 1024 && (
                        <TopNavMenuItem overlay={profileMenu} trigger={['click']} placement="bottomRight">
                          <ProfileInfoContainer user={user} />
                        </TopNavMenuItem>
                      )}
                    </RightNav>
                  </>
                );
              }
              return (
                <>
                  <MiddleNav>
                    {(width > 576 || (width < 576 && !['/market', '/favorite'].includes(location.pathname))) && (
                      <InputContainer ref={searchInputContainer}>
                        <CustomSearchInput
                          ref={searchInput}
                          onChange={onChangeSearch}
                          onPressEnter={handleOnSearch}
                          value={keyword}
                          placeholder={`${translations(messages.search)} ${translations(
                            messages.in,
                          )} ${renderCategoryName(category || undefined, search, 'KAMEREO')}`}
                          size="default"
                          suffix={
                            <SearchOutlined
                              onClick={(e) => {
                                e.stopPropagation();
                                handleOnSearch();
                              }}
                              style={{ fontSize: '21px', color: 'rgb(67, 160, 71)', cursor: 'pointer' }}
                            />
                          }
                        >
                          <AntdInput onFocus={() => setIsOpenSearchResultDropdown(true)} />
                        </CustomSearchInput>
                        {isOpenSearchResultDropdown && (
                          <SearchWithDropDownComponent
                            keyword={tempKeyword}
                            onCloseDropdown={() => setIsOpenSearchResultDropdown(false)}
                          />
                        )}
                      </InputContainer>
                    )}
                    {['/market', '/favorite'].includes(location.pathname) && width < 1025 && <MainCategoryNavMobile />}
                  </MiddleNav>
                  <RightNav>
                    {width > 1024 && (
                      <LinkText to="/signin" rel="nofollow">
                        <HeaderIconWrapper className={['/favorite'].includes(location.pathname) ? 'active' : ''}>
                          <SVGIcon src={FavoriteIcon} />
                        </HeaderIconWrapper>
                      </LinkText>
                    )}
                    {width <= 767 && (
                      <SwitchStoreMobileContainer>
                        <Link to="/contact-us">
                          <SignUpButton>{translations(topNavMessage.signUp)}</SignUpButton>
                        </Link>
                      </SwitchStoreMobileContainer>
                    )}
                    {width <= 767 && <SVGIcon src={HamburgerMenu} onClick={handleToggleSideNav} />}
                    {width > 1024 && (
                      <>
                        <LinkText to="/signin" rel="nofollow">
                          <HeaderIconWrapper
                            className={location.pathname.split('/').includes('checkout') ? 'active' : ''}
                          >
                            <SVGIcon src={Cart} className="relative-position" />
                            <TopNavBadge>0</TopNavBadge>
                          </HeaderIconWrapper>
                        </LinkText>
                      </>
                    )}
                    <SignInSignUpWrapper>
                      <LinkText to="/signin">
                        <TopNavButtonHiddenOnMobile>
                          <FormattedMessage {...messages.signin} />
                        </TopNavButtonHiddenOnMobile>
                      </LinkText>
                      <LinkText to="/contact-us">
                        <TopNavButtonHiddenOnMobile className="contact-us">
                          <Button type="primary" size="small">
                            <FormattedMessage {...messages.signup} />
                          </Button>
                        </TopNavButtonHiddenOnMobile>
                      </LinkText>
                    </SignInSignUpWrapper>
                  </RightNav>
                </>
              );
            }}
          </Viewport.Consumer>
          <Viewport.Consumer>
            {({ width }) =>
              isOpenCategory &&
              width > 991 && <CategoryNav overlay={true} onCloseCategory={() => setIsOpenCategory(false)} />
            }
          </Viewport.Consumer>
        </TopNavContent>
      </TopNavWrapper>
      <TopNavCategory cart={cart} permissions={userPermissions} />
      <Suspense fallback={<LoadingIndicator />}>
        {isSideBarMounted && (
          <SideNavMobileComponent onCloseSideNav={handleToggleSideNav} opened={isOpenSideNav} onSetLang={onSetLang} />
        )}
      </Suspense>
    </>
  );
};

export default withRouter(TopNav);
